import styles from './styles.module.css'
import { ActionsTariffAtom, HeadertariffAtom } from './atoms'
import { AdvantesAtomRedesign } from './atoms/advantes'
import { SubscriptionType } from '../../typing/enums'
import _ from 'lodash'
import { themeTariff } from '../../configs/advantes.config'

interface IProps {
    label: string
    price: string
    onClick: () => void
    advantes: string[]
    includeBasic?: boolean
    type: SubscriptionType
    isPrimary?: boolean
    typePayment: string
    typeSubscription: SubscriptionType
}

export const TariffRedesign = ({
    label,
    price,
    type,
    advantes,
    includeBasic,
    typePayment,
    isPrimary,
    typeSubscription,
    onClick,
}: IProps) => {
    const theme = themeTariff[typeSubscription]

    return (
        <div
            className={styles.container}
            style={{ backgroundColor: theme.background }}>
            <HeadertariffAtom
                typeSubscription={typeSubscription}
                isPrimary={isPrimary}
                typeTariff={label}
                price={price}
                typePayment={typePayment}
            />

            <AdvantesAtomRedesign
                typeSubscription={typeSubscription}
                type={type}
                allBasic={includeBasic}
                list={advantes}
            />

            <ActionsTariffAtom
                typeSubscription={typeSubscription}
                buyNow={onClick}
                buyTrial={onClick}
            />
        </div>
    )
}
