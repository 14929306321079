import { FormControll, PrimaryButton } from '@/core/components'
import { getDataFromApiError } from '@/core/helpers'
import { useForm } from '@/core/hooks'
import { useState } from 'react'
import { validateEmail } from '../../validators'
import { requestOTPReq } from '../../api'
import { AuthStep, IStep } from '../../typing'
import { gcService } from '@/core/tools'
import styles from './styles.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

interface FormState {
    email: string
}

interface IProps {
    changeStep: (val: IStep) => void
}
export const ForgotPasswordForm = ({ changeStep }: IProps) => {
    const { t } = useTranslation()
    const { state } = useLocation()
    const navigate = useNavigate()
    const starSymbol = '\u002A'
    const { values, setField, errors, onSubmit } = useForm<FormState>(
        {},
        validateEmail,
    )

    const [error, setError] = useState(null)

    const handleChange = (val: string) => {
        setField('email', val)
        setError(null)
    }

    const submit = async () => {
        try {
            await requestOTPReq(values.email)
            changeStep({
                name: AuthStep.SendCode,
                params: { email: values.email },
            })
            gcService.set('email', values.email)
        } catch (error: any) {
            const errorApi: any = getDataFromApiError(error)
            setError(errorApi?.message)
            Sentry.captureException(error)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.container_labels}>
                <p className={styles.title}>
                    {state?.isChange ? t('changePassword') : t('resetPassword')}
                </p>

                <p className={styles.subTitle}>
                    {t('messageSendCodeStart')}
                    <br />
                    <span>{t('messageSendCodeEnd')}</span>
                </p>
            </div>

            <div className={styles.email_input}>
                <FormControll
                    onChangeVal={handleChange}
                    value={values.email}
                    label={`${t('email')} ${starSymbol}`}
                    error={errors.email || error}
                />
            </div>

            <div className={styles.buttonBlock}>
                <PrimaryButton
                    label={t('continue')}
                    onClick={() => onSubmit(submit)}
                    className={styles.btn}
                    withArrow={true}
                />
                {state?.isChange ? null : (
                    <p className={styles.signIn}>
                        {t('rememberPassword')}{' '}
                        <span
                            onClick={() =>
                                navigate('/auth', {
                                    state: { step: AuthStep.Login },
                                })
                            }
                            className={styles.labelSignIn}>
                            {t('signIn')}
                        </span>
                    </p>
                )}
            </div>
        </div>
    )
}
