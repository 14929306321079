import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { themeTariff } from '@/modules/subscribe/configs/advantes.config'

interface IProps {
    list: string[]
    allBasic: boolean
    type: SubscriptionType
    typeSubscription: SubscriptionType
}
export const AdvantesAtomRedesign = ({
    list,
    allBasic,
    typeSubscription,
}: IProps) => {
    const theme = themeTariff[typeSubscription]
    const isBasic =
        typeSubscription === SubscriptionType.Basic ||
        typeSubscription === SubscriptionType.Basic_3m

    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <p className={styles.title} style={{ color: theme.txtColor }}>
                {allBasic ? `${t('allBasic')} +` : `${t('whatIncludes')}:`}
            </p>
            <div
                className={styles.list}
                style={{ gridTemplateColumns: isBasic ? '1fr 1fr' : '1fr' }}>
                {list.map(it => (
                    <div key={it} className={styles.advantages_item}>
                        <img
                            src={theme.iconCheck}
                            style={{ height: 20, width: 20 }}
                        />
                        <p
                            className={styles.advantages_item_label}
                            style={{ color: theme.txtColor }}>
                            {t(it)}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}
