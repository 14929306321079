import { ConfigProvider, Slider } from 'antd'

import styles from './styles.module.css'
import { useState } from 'react'
import { appEvents } from '@/core/events'
import { useEventsListener, useResizeWindow } from '@/core/hooks'

export const SliderControlSea = () => {
    const { width: widthWindow } = useResizeWindow()

    const [level, setLevel] = useState(-30)

    const [show, setShow] = useState(true)

    useEventsListener('seaLevel', data => {
        if (widthWindow <= 767) {
            setShow(data?.isShow)
        }
        setLevel(data.level)
    })

    const onChange = (value: number) => {
        appEvents.emit('seaLevel', { level: value, isShow: show })
        setLevel(value)
    }

    if (!show) {
        return null
    }

    return (
        <div className={styles.slider_controll}>
            <ConfigProvider
                theme={{
                    components: {
                        Slider: {
                            dotActiveBorderColor: '#FFFFFF',
                            handleColor: '#0D8F5B',
                            handleActiveColor: '#0D8F5B',
                        },
                    },
                }}>
                <Slider
                    tooltip={{
                        formatter(value) {
                            return value < 0 ? 0 : value
                        },
                    }}
                    value={level}
                    min={-25}
                    onChange={onChange}
                    max={999}
                    className={styles.slider}
                    styles={{
                        rail: { backgroundColor: '#0D8F5B' },
                        handle: { backgroundColor: '#0D8F5B' },
                        track: {
                            backgroundColor: '#0D8F5B',
                            color: '#0D8F5B',
                        },
                        tracks: { backgroundColor: '#0D8F5B' },
                    }}
                />
            </ConfigProvider>

            <p style={{ fontSize: 14 }}>{level < 0 ? 0 : level} m</p>
        </div>
    )
}
