import { Icon, PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { useAccountStore } from '@/store'
import { SubscriptionStatus } from '../../typing/enums'
import { appEvents } from '@/core/events'
import { useTranslation } from 'react-i18next'

export const BlurSubscription = () => {
    const { t } = useTranslation()
    const { account } = useAccountStore()

    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    const isSubscribe = account?.subscription

    const buySubscribe = () => {
        appEvents.emit('account', { isShow: false })
        appEvents.emit('subsribe', { isShow: true, method: 'buy' })
    }
    if (isSubscribe && !isTrial) {
        return null
    }
    return (
        <div className={styles.container}>
            <button onClick={buySubscribe} className={styles.btn}>
                <div className={styles.row}>
                    <Icon name='warning-2' size={14} color='#FFAE00' />
                    <p className={styles.label}>
                        {t('fullSubscriptionRequired')}
                    </p>
                </div>

                <PrimaryButton
                    onClick={buySubscribe}
                    className={styles.buy_btn}
                    labelStyle={{
                        fontSize: 12,
                        fontWeight: '500',
                        lineHeight: '18px',
                    }}
                    label={t('buyNow')}
                />
            </button>
        </div>
    )
}
