import { PrimaryButton } from '../primary-button'
import googleLogo from '@/assets/images/google.svg'
import facebookLogo from '@/assets/images/facebook.svg'
import styles from './styles.module.css'

const socialLogo = {
    google: googleLogo,
    facebook: facebookLogo,
}

interface IProps {
    onClick: () => void
    label: string
    socialName: 'google' | 'facebook'
    disabled?: boolean
    className?: string
}

export const SocialButton = ({
    onClick,
    label,
    socialName,
    disabled,
    className,
}: IProps) => {
    return (
        <div
            className={styles.container}
            style={{
                opacity: disabled ? 0.8 : 1,
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}>
            <PrimaryButton
                className={`${styles.label}${className ? ` ${className}` : ''}`}
                disabled={disabled}
                leftIcon={<img src={socialLogo[socialName]} />}
                label={label}
                onClick={onClick}
            />
        </div>
    )
}
