import { PrimaryButton } from '@/core/components/buttons'
import styles from './styles.module.css'
import { Icon } from '@/core/components/icons'

interface IProps {
    goBack: () => void
    next: (e: any) => void
    labelBack: string
    labelNext: string
    isFirst: boolean
    isLast: boolean
}
export const GroupButtonsAtom = ({
    goBack,
    next,
    labelBack,
    labelNext,
}: IProps) => {
    return (
        <div className={styles.btn_group}>
            <PrimaryButton
                className={styles.outline_btn}
                labelStyle={{
                    fontSize: 14,
                    color: '#262933',
                    fontWeight: '500',
                }}
                onClick={goBack}
                label={labelBack}
            />

            <PrimaryButton
                className={styles.primary_btn}
                labelStyle={{
                    fontSize: 14,
                    fontWeight: '500',
                    color: '#FFFFFF',
                }}
                onClick={next}
                label={labelNext}
                rightIcon={
                    <Icon name='arrow-right' size={16} color='#FFFFFF' />
                }
            />
        </div>
    )
}
