import styles from './styles.module.css'
import { Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    onClick: () => void
}
export const SubscriptionPansBtn = ({ onClick }: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container} onClick={onClick}>
            <div className={styles.star_block}>
                <Icon name='magic-star' color='#0D8F5B' size={21.33} />
            </div>
            <div>
                <p className={styles.extra_label}>{t('subscriptionPlans')}</p>
                <div className={styles.row}>
                    <p className={styles.extra_sublabel}>{t('exploreNow')} </p>
                    <div className={styles.icon}>
                        <Icon name='arrow-right' size={17.68} color='#AFB1B6' />
                    </div>
                </div>
            </div>
        </div>
    )
}
