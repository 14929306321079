import { useEffect, useMemo, useState } from 'react'
import { getGroupForecastReq } from '../../api'
import { Loader } from '@/core/components'
import {
    IAstronomy,
    ICurrentDay,
    IWeatherForecastDayResponse,
} from '../../api/interfaces'
import {
    CurrentWeather,
    Forecast,
    HeaderWeather,
    MobileHeaderWeather,
    UVIndexProgress,
    WeatherAlerts,
    WeatherTable,
} from '../../components'
import { FullNotiicationsWidget } from '@/modules/notifications/widgets'
import styles from './styles.module.css'
import moment from 'moment'
import { IWeatherAlert } from '../../typing/interface'
import _ from 'lodash'
import { getDataForRows } from '../../utils'
import * as Sentry from '@sentry/react'
import { AccountPage } from '@/modules/user/pages'
import {
    useLanguageStore,
    useSearchCityStore,
    useWeatherLocationStore,
} from '@/store'
import { useGetRows } from '../../hooks'
import { useEventsListener } from '@/core/hooks'

export const DetailedWeather = () => {
    const [show, setShow] = useState(false)
    const { weatherLocation } = useWeatherLocationStore()
    const { lang } = useLanguageStore()

    const [weather, setWeather] = useState(null)
    const [forecast, setForecast] =
        useState<IWeatherForecastDayResponse[]>(null)
    const [currentWeather, setCurrentWeather] = useState<ICurrentDay>(null)
    const [astronomy, setAstronomy] = useState<IAstronomy>(null)
    const [isloading, setIsLoading] = useState(false)
    const [alerts, setAlerts] = useState<IWeatherAlert[]>([])
    const [uvIndex, setUvIndex] = useState(0)
    const { clearCity } = useSearchCityStore()

    useEventsListener('weatherDetailed', data => setShow(data?.isShow), [])

    const getWeather = async () => {
        setIsLoading(true)
        try {
            const { data } = await getGroupForecastReq(
                `${weatherLocation.lat},${weatherLocation.lng}`,
            )

            setForecast(
                data.forecast.forecastday.filter(it =>
                    moment(it.date).isAfter(moment().format('YYYY-MM-DD')),
                ),
            )

            setCurrentWeather(data.current)
            setAstronomy(data.forecast.forecastday[0].astro)

            const forecastHours = data.forecast.forecastday[0].hour
            const weatherZones = getDataForRows(forecastHours)

            setWeather(weatherZones)

            setAlerts(
                data.alerts.alert.map(it => ({
                    category: it?.category,
                    description: it?.desc,
                    date: `Expires ${moment(it?.expires).format('MMMM DD')}, ${moment(it?.expires).format('hh:mm')}`,
                    title: it?.headline,
                })),
            )
            setUvIndex(data.current.uv <= 12 ? data.current.uv : 12)
        } catch (error) {
            Sentry.captureException(error)
        }
        setIsLoading(false)
    }

    const rows = useGetRows(weather)

    useEffect(() => {
        getWeather()
    }, [weatherLocation?.city])

    const currentWeatherBlock = useMemo(() => {
        if (!currentWeather) return null
        return (
            <CurrentWeather
                icon={currentWeather?.condition?.icon}
                last_updated={currentWeather?.last_updated}
                temp_c={currentWeather?.temp_c}
                sunrise={astronomy?.sunrise}
                sunset={astronomy?.sunset}
                realFeel={currentWeather?.feelslike_c}
                wind={{
                    dir: currentWeather?.wind_dir,
                    kph: currentWeather?.wind_kph,
                }}
                pressure={currentWeather?.pressure_mb}
                humidity={currentWeather?.humidity}
            />
        )
    }, [currentWeather, weatherLocation, lang])

    const forecastBlock = useMemo(() => {
        if (!forecast) return
        return <Forecast forecast={forecast} />
    }, [forecast, weatherLocation])

    return (
        <div
            className={`${styles.container} ${show ? styles?.visible : ''}`}
            onClick={() => setShow(false)}>
            <div
                className={`${styles.layout} ${show ? styles?.visible : ''}`}
                onClick={e => e.stopPropagation()}>
                {isloading ? (
                    <Loader />
                ) : (
                    <>
                        <HeaderWeather
                            clearSearchCity={clearCity}
                            weatherLocation={{
                                region: _.defaultTo(
                                    weatherLocation?.region,
                                    '',
                                ),
                                city: _.defaultTo(weatherLocation?.city, ''),
                                country: weatherLocation?.country,
                            }}
                        />
                        <MobileHeaderWeather
                            clearSearchCity={clearCity}
                            weatherLocation={{
                                region: _.defaultTo(
                                    weatherLocation?.region,
                                    '',
                                ),
                                city: _.defaultTo(weatherLocation?.city, ''),
                                country: weatherLocation?.country,
                            }}
                        />
                        <div className={styles.content}>
                            <div className={styles.row}>
                                <>{currentWeatherBlock}</>

                                <>{forecastBlock}</>
                            </div>

                            <WeatherTable data={rows} />
                            <div className={styles.row_grid}>
                                <WeatherAlerts alerts={alerts} />

                                <UVIndexProgress uvIndex={uvIndex} />
                            </div>
                        </div>
                        <FullNotiicationsWidget />
                        <AccountPage />
                    </>
                )}
            </div>
        </div>
    )
}
