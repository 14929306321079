import { RadioButton } from '@/core/components'
import styles from './styles.module.css'

interface IProps {
    onClick: () => void
    isActive: boolean
    label: string
}
export const RadioVariantAtom = ({ onClick, isActive, label }: IProps) => {
    return (
        <button onClick={onClick} className={styles.btn_container}>
            <RadioButton
                color={isActive ? '#0D8F5B' : ''}
                isActive={isActive}
            />
            <p>{label}</p>
        </button>
    )
}
