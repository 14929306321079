import { usePlacesWidget } from 'react-google-autocomplete'
import { useEffect, useState } from 'react'
import { $eventVal } from '@/core/helpers'
import { useSearchCityStore } from '@/store'
import { Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

export const SearchCity = () => {
    const { t } = useTranslation()
    const isOpen = localStorage.getItem('BAR')
    const { setCity } = useSearchCityStore()
    const [value, setValue] = useState('')

    const { ref: antRef }: any = usePlacesWidget({
        apiKey,
        onPlaceSelected: place => {
            parseLocation(place)
        },
        language: 'en',
        inputAutocompleteValue: '(cities)',
        options: {
            types: ['(cities)'],
        },
    })

    const parseLocation = (place: any) => {
        const place_id = place.place_id
        const location = place.geometry?.location

        const cityName = place.address_components[0]
        setValue(cityName?.long_name)

        setCity({
            placeId: place_id,
            name: cityName?.long_name,
            lng: location.lng(),
            lat: location.lat(),
        })
    }

    useEffect(() => {
        if (isOpen) {
            antRef.current.blur()
        }
    }, [isOpen, antRef])

    return (
        <div
            className={'container'}
            style={{
                backgroundColor: '#F7F7F7',
                borderRadius: 90,
            }}>
            <Icon
                name='search-normal'
                size={24}
                color='#97999A'
                style={{ position: 'absolute', left: 12 }}
            />

            <input
                autoComplete='off'
                className={'search_input'}
                ref={antRef}
                value={value}
                onChange={e => {
                    setValue($eventVal(e))
                }}
                placeholder={t('searchCity')}
            />
        </div>
    )
}
