import { useEffect } from 'react'
import './style.css'
const WORLDS_BOUNDS = {
    north: 85.6,
    south: -60.0,
    east: 180.0,
    west: -185.0,
}

const CENTER_OF_EUROPE = { lat: 50, lng: 25.0 }

export const NaturalMap = () => {
    const initMap = async () => {
        const { Map } = (await google.maps.importLibrary(
            'maps',
        )) as google.maps.MapsLibrary
        //init map
        new Map(document.getElementById('natural-map'), {
            center: CENTER_OF_EUROPE,
            zoom: 3,

            mapId: '6c5168d0058f979b',
            mapTypeControl: false,
            minZoom: 3,
            maxZoom: 7,
            gestureHandling: 'none',
            streetViewControl: false,
            fullscreenControl: false,
            restriction: {
                latLngBounds: WORLDS_BOUNDS,
                strictBounds: false,
            },
            zoomControl: false,
            styles: [
                {
                    featureType: 'all',
                    elementType: 'all',
                    stylers: [{ visibility: 'off', cursor: 'default' }],
                },
            ],
        })
    }

    useEffect(() => {
        initMap()
    }, [])

    return (
        <div id='natural-map' style={{ width: '100%', height: '100dvh' }}></div>
    )
}
