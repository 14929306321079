import { useEffect, useState } from 'react'
import { ActionFooter, QuestionsPagination } from '../../components'
import { RadioVariantAtom } from './atoms/radio-variant.atom'
import styles from './styles.module.css'
import { IQuestion } from '@/modules/chat-bot/typing/interface'

interface IProps {
    countApprove: number
    stepNumber: number
    question: string
    answers: string[]
    onChangeStep: (val: number) => void
    onSetAnswer: (val: IQuestion) => void
    close: () => void
    approve: IQuestion
}
export const OnceAnwerStep = ({
    question,
    answers,
    stepNumber,
    onChangeStep,
    onSetAnswer,
    close,
    countApprove,
    approve,
}: IProps) => {
    const [answer, setAnswer] = useState('')

    useEffect(() => {
        setAnswer(approve?.answer ? String(approve?.answer) : '')
    }, [approve, stepNumber])

    const onChangeAnswer = (val: string) => {
        setAnswer(val)
    }

    const handleApproveAnswer = () => {
        onSetAnswer({ question, answer: [answer], id: stepNumber })
        onChangeStep(stepNumber + 1)
    }

    return (
        <div className={styles.container}>
            <QuestionsPagination
                disabledNext={countApprove == stepNumber}
                current={stepNumber}
                prev={() => onChangeStep(stepNumber - 1)}
                next={() => onChangeStep(stepNumber + 1)}
                length={7}
            />

            <p className={styles.title}>{question}</p>

            <div className={styles.answers_container}>
                {answers?.map(it => (
                    <RadioVariantAtom
                        onClick={() => onChangeAnswer(it)}
                        isActive={answer === it}
                        label={it}
                    />
                ))}
            </div>

            <ActionFooter
                next={handleApproveAnswer}
                exit={close}
                disabledNext={!answer}
            />
        </div>
    )
}
