import { CSSProperties, useMemo } from 'react'
import styles from './style.module.css'
import { Icon } from '../../icons'
interface Props {
    onClick: (e?: any) => void
    label: string
    disabled?: boolean
    isLoading?: boolean
    leftIcon?: JSX.Element | null
    style?: CSSProperties
    labelStyle?: CSSProperties
    className?: string
    id?: string
    rightIcon?: JSX.Element | null
    withArrow?: boolean
}
export const PrimaryButton = ({
    onClick,
    label,
    style,
    labelStyle,
    disabled,
    isLoading,
    leftIcon = null,
    className,
    id,
    rightIcon,
    withArrow,
}: Props) => {
    const getButtonClass = () => {
        switch (true) {
            case disabled:
                return `${styles.disabled} ${styles.button_container}`
            default:
                return styles.button_container
        }
    }
    const content = useMemo(() => {
        if (isLoading) {
            return <div className={styles.loader}></div>
        } else {
            return (
                <div className={styles.content}>
                    <>{leftIcon}</>
                    <p
                        className={
                            disabled ? styles.white_label : styles.button_label
                        }
                        style={labelStyle}>
                        {label}
                    </p>
                    <>{rightIcon}</>
                    {withArrow && (
                        <Icon
                            name='arrow-right'
                            size={24}
                            color='#ffffff'
                            style={{ marginTop: 2 }}
                        />
                    )}
                </div>
            )
        }
    }, [isLoading, label, labelStyle, disabled])
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            style={style}
            id={id}
            className={`${getButtonClass()} ${className}`}>
            {content}
        </button>
    )
}
