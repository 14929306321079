import { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.css'
import { getWrapperStep } from './helpers/step-wrapper.helper'
import { questionsConfig } from './config/questions.config'
import { IQuestion } from '../../typing/interface'
import { GreetingStep } from './steps'
import * as Sentry from '@sentry/react'
import { sendChatQuestionsReq } from '../../api'
import { ModalQuestionsReject } from './components/modal-reject'
import { useLanguageStore } from '@/store'

interface IProps {
    close: () => void
    openModal: () => void
}

export const BotQuestionnaireWIdget = ({ close, openModal }: IProps) => {
    const { lang } = useLanguageStore()
    const [step, setStep] = useState(0)
    const [answerData, setAnswerData] = useState<IQuestion[]>([])
    const [isGreeting, setIsGreeting] = useState(true)
    const [modalReject, setModalReject] = useState(false)

    const stepData = useMemo(() => questionsConfig()[step], [lang, step])

    const StepWrapper = useMemo(() => getWrapperStep(stepData?.type), [step])

    const sendQuestionsToChatBot = async () => {
        try {
            const payload = answerData.map(it => ({
                q: it.question,
                a: it.answer.join(', '),
            }))
            await sendChatQuestionsReq(payload)
            openModal()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    useEffect(() => {
        if (answerData.length === 7) {
            close()
            sendQuestionsToChatBot()
        }
    }, [answerData])

    const onChangeAnswerData = (val: IQuestion) => {
        if (answerData.some(it => it.id === val.id)) {
            const updateAnswer: IQuestion = {
                ...val,
                answer: val.answer,
            }
            setAnswerData(prev => {
                prev[val.id] = updateAnswer
                return prev
            })
        } else {
            setAnswerData([...answerData, val])
        }
    }

    const handleCloseQuestionare = () => {
        setModalReject(true)
    }
    const stepComponent = useMemo(
        () => (
            <StepWrapper
                close={handleCloseQuestionare}
                onSetAnswer={onChangeAnswerData}
                countApprove={answerData.length}
                approve={answerData[step]}
                onChangeStep={setStep}
                stepNumber={step}
                answers={stepData?.answer as string[]}
                question={stepData?.question}
            />
        ),
        [step, answerData, stepData],
    )

    return (
        <div className={`${styles.container} `}>
            <>
                {isGreeting ? (
                    <GreetingStep
                        close={close}
                        onChangeStep={() => setIsGreeting(false)}
                    />
                ) : (
                    <div className={styles.steps}>{stepComponent}</div>
                )}
            </>

            <ModalQuestionsReject
                isOpen={modalReject}
                close={() => setModalReject(false)}
                confirm={close}
            />
        </div>
    )
}
