import { CategoryRisks } from '@/core/typing'
import { Placement } from 'react-joyride'

export const stepsIntro = [
    {
        target: '#category-0',
        content: 'conflictsInstruction',
        disableBeacon: true,
        title: 'conflicts',
        placement: 'right-start' as Placement,
        key: CategoryRisks.Conflicts,
    },

    {
        target: '#legend',
        content: 'legendInstruction',
        title: 'legend',
        placement: 'top' as Placement,
        key: 'legend',
    },

    {
        target: '#mapborder',
        content: 'mapInstructions',
        title: 'map',
        key: 'map',
    },

    {
        target: '#category-1',
        content: 'socialIstruction',
        title: 'social',
        disableBeacon: true,
        placement: 'right-start' as Placement,
        key: CategoryRisks.Social,
    },
    {
        target: '#category-2',
        content: 'healthInstruction',
        title: 'medical',
        disableBeacon: true,
        placement: 'right-start' as Placement,
        key: CategoryRisks.Medical,
    },
    {
        target: '#category-3',
        content: 'climateInstruction',
        title: 'climate',
        disableBeacon: true,
        placement: 'right-start' as Placement,
        key: CategoryRisks.Climate,
    },

    {
        target: '#category-4',
        content: 'requirementsInstruction',
        title: 'requirements',
        disableBeacon: true,
        placement: 'right-start' as Placement,
        key: CategoryRisks.Requirements,
    },
    {
        target: '#profile',
        content: 'accountInstruction',
        title: 'personalAccount',
        disableBeacon: true,
        placement: 'bottom' as Placement,
        key: 'account',
    },
    {
        target: '#category-5',
        content: 'LGBTInstruction',

        disableBeacon: true,
        title: 'LGBTQ',
        placement: 'right-start' as Placement,
        key: CategoryRisks.LGBTQ,
    },
    {
        target: '#category-6',
        content: 'womenInstruction',
        disableBeacon: true,
        title: 'women',
        placement: 'right-start' as Placement,
        key: CategoryRisks.Women,
    },
    {
        target: '#travy',
        content: 'chatBotInstruction',
        title: 'chatBot',
        placement: 'top' as Placement,
        key: 'chat-bot',
    },
]

export const stepIconsConfig: any = {
    [CategoryRisks.Conflicts]: 'huobi-token',
    [CategoryRisks.Social]: 'profile-2user',
    [CategoryRisks.Medical]: 'heart-1',
    [CategoryRisks.Climate]: 'flash',
    [CategoryRisks.Requirements]: 'book',
    [CategoryRisks.LGBTQ]: 'lgbt',
    [CategoryRisks.Women]: 'women',
    ['legend']: 'command',
    ['map']: 'global',
    ['account']: 'profile-circle',
    ['chat-bot']: 'message-question',
}
