import { useState } from 'react'
import styles from './styles.module.css'
import { Icon } from '@/core/components'
import { Modal } from 'antd'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useAccountStore, useCategoryRiskStore } from '@/store'
import { appEvents } from '@/core/events'
import { SubcategoryRisks } from '@/core/typing'
import { useTranslation } from 'react-i18next'

export const ContuctUsMobile = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { account } = useAccountStore()
    const { active } = useCategoryRiskStore()
    const [modal, setModal] = useState<boolean>(false)
    const toggleModal = () => {
        setModal(() => !modal)
    }

    const goToPage = (keyPage: 'terms' | 'policy') => {
        setModal(false)

        if (account) {
            return navigate(`/${keyPage}`)
        } else {
            navigate(`/auth/${keyPage}`)
        }
    }
    const openAboutUs = () => {
        const event = account ? 'aboutUs' : 'contactUs'
        appEvents.emit(event, { isShow: true })
    }
    return (
        <>
            <div
                style={{
                    left:
                        active?.subcategory === SubcategoryRisks?.Weather
                            ? 80
                            : 130,
                }}
                className={styles.inform_btn}>
                <Icon
                    className={styles.icon_filter}
                    onClick={toggleModal}
                    name={modal ? 'x' : 'inform'}
                    size={24}
                    color='#0F0E0E'
                />
            </div>

            <Modal
                transitionName=''
                maskTransitionName=''
                closeIcon={null}
                footer={null}
                classNames={{
                    content: styles.modal,
                    body: styles.modal_body,
                }}
                open={modal}>
                <div className={styles.modal_content}>
                    <p
                        className={styles.item_txt}
                        onClick={() => goToPage('terms')}>
                        {t('terms')}
                    </p>
                    <p
                        className={styles.item_txt}
                        onClick={() => goToPage('policy')}>
                        {t('policy')}
                    </p>

                    <p className={styles.item_txt} onClick={openAboutUs}>
                        {account ? t('aboutUs') : 'FAQ'}
                    </p>

                    <p className={styles.note}>
                        © 2024 SafeTravy. {t('rightsReserved')}.
                    </p>
                </div>

                <div
                    style={{
                        left:
                            active?.subcategory === SubcategoryRisks.Weather
                                ? 60
                                : 110,
                    }}
                    className={styles.close_modal}
                    onClick={toggleModal}>
                    <Icon
                        className={styles.icon_filter}
                        name='x'
                        size={24}
                        onClick={_.noop}
                    />
                </div>
            </Modal>
        </>
    )
}
