import { Modal } from 'antd'
import styles from './styles.module.css'
import { Icon } from '../icons'
import { PrimaryButton } from '../buttons'
import { useTranslation } from 'react-i18next'
import { IMobileStep } from './step.config'
import { stepIconsConfig } from '../intro-step/step.config'
import { useLanguageStore } from '@/store'
import { CategoryRisks, Languages } from '@/core/typing'

interface IProps {
    isShow: boolean
    close: () => void
    nextStep: () => void
    prevStep: () => void
    step: IMobileStep
    currentStepIndex: number
    lengthSteps: number
    isLast: boolean
    isFirst: boolean
}
export const IntroStepMobile = ({
    isFirst,
    isLast,
    isShow,
    close,
    nextStep,
    prevStep,
    step,
    currentStepIndex,
    lengthSteps,
}: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()

    const subsctibeTitle = (title: string) => {
        if (lang === Languages.DE && step.key === CategoryRisks.Requirements) {
            return `${title.slice(0, 10)}... `
        }
        return title
    }
    return (
        <Modal
            style={{ top: 20 }}
            styles={{ body: { width: '100%', overflow: 'hidden' } }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={isShow}>
            <div className={styles.content_modal}>
                <div className={styles.header}>
                    <div className={styles.row_header}>
                        <Icon
                            name={stepIconsConfig[step.key]}
                            size={16}
                            color='#FFFFFF'
                            className={styles.icon_category}
                        />
                        <p className={styles.title}>
                            {subsctibeTitle(t(`${step.title}`))}:
                        </p>
                    </div>
                    <button onClick={close} className={styles.cross}>
                        {t('skip')}
                    </button>
                </div>

                <div className={styles.description_container}>
                    <p className={styles.content}>{t(`${step?.content}`)}</p>
                </div>

                <img
                    src={step?.illustarionPath}
                    className={styles.illustration}
                />
                <div className={styles.action_group}>
                    <p>
                        {currentStepIndex}
                        <span className={styles.static_pagination}>
                            /{lengthSteps}
                        </span>
                    </p>
                    <div className={styles.btn_group}>
                        <PrimaryButton
                            className={styles.outline_btn}
                            labelStyle={{
                                fontSize: 14,
                                color: '#262933',
                                fontWeight: '500',
                            }}
                            onClick={isFirst ? close : prevStep}
                            label={isFirst ? t('quit') : t('back')}
                        />
                        <PrimaryButton
                            className={styles.primary_btn}
                            labelStyle={{
                                fontSize: 14,
                                fontWeight: '500',
                                color: '#FFFFFF',
                            }}
                            label={isLast ? t('done') : t('next')}
                            onClick={isLast ? close : nextStep}
                            rightIcon={
                                <Icon
                                    name='arrow-right'
                                    size={16}
                                    color='#FFFFFF'
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
