import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { getPercentage } from '../../utils'
import { useTranslation } from 'react-i18next'

interface IProps {
    uvIndex: number
}
export const UVIndexProgress = ({ uvIndex }: IProps) => {
    const { t } = useTranslation()
    const percentage = getPercentage(uvIndex)
    const [animatedPercentage, setAnimatedPercentage] = useState(0)

    useEffect(() => {
        let start = 5
        const duration = 500
        const increment = percentage / (duration / 10)

        const animateProgress = () => {
            start += increment
            if (start >= percentage) {
                setAnimatedPercentage(percentage)
            } else {
                setAnimatedPercentage(start)
                requestAnimationFrame(animateProgress)
            }
        }

        animateProgress()
    }, [percentage])

    const strokeDasharray = (2 * Math.PI * 40) / 2
    const strokeDashoffset =
        strokeDasharray - (strokeDasharray * animatedPercentage) / 100

    return (
        <div className={styles.container}>
            <p className={styles.label_index}>{t('UVIndex')}</p>
            <div>
                <div className={styles.progress_container}>
                    <div className={styles.circle_container}>
                        <div className={styles.blue_circle}>
                            <div className={styles.white_circle}></div>
                            <div className={styles.full_circle}></div>
                        </div>
                    </div>
                    <svg
                        className={styles.progress_circle}
                        viewBox='0 0 100 50'>
                        <defs>
                            <linearGradient
                                id='gradient'
                                x1='0%'
                                y1='0%'
                                x2='100%'
                                y2='0%'>
                                <stop
                                    offset='0%'
                                    style={{
                                        stopColor: '#0D8F5B',
                                        stopOpacity: 1,
                                    }}
                                />
                                <stop
                                    offset='100%'
                                    style={{
                                        stopColor: '#0D8F5B',
                                        stopOpacity: 1,
                                    }}
                                />
                            </linearGradient>
                        </defs>

                        <path
                            d='M 10,50 A 40,40 0 0,1 90,50'
                            stroke='url(#gradient)'
                            strokeWidth='5'
                            strokeLinecap='round'
                            fill='none'
                            strokeDasharray={strokeDasharray}
                            strokeDashoffset={strokeDashoffset}
                        />
                        <g>
                            {animatedPercentage >= 10 ? (
                                <circle cx='11' cy='43' r='1' fill='#FFFFFF' />
                            ) : null}

                            <text
                                x='20.5'
                                y='46'
                                textAnchor='middle'
                                fill='#000000'
                                fontSize='6'>
                                0
                            </text>
                        </g>
                        <g>
                            {animatedPercentage >= 30 ? (
                                <circle cx='24' cy='20' r='1' fill='#FFFFFF' />
                            ) : null}
                            <text
                                x='30'
                                y='29'
                                textAnchor='middle'
                                fill='#000000'
                                fontSize='6'>
                                3
                            </text>
                        </g>

                        <g>
                            {animatedPercentage >= 50 ? (
                                <circle cx='50' cy='10' r='1' fill='#FFFFFF' />
                            ) : null}
                            <text
                                x='50'
                                y='22'
                                textAnchor='middle'
                                fill='#000000'
                                fontSize='6'>
                                6
                            </text>
                        </g>
                        <g>
                            {animatedPercentage >= 73 ? (
                                <circle
                                    cx='76.3'
                                    cy='20'
                                    r='1'
                                    fill='#FFFFFF'
                                />
                            ) : null}
                            <text
                                x='70'
                                y='29'
                                textAnchor='middle'
                                fill='#000000'
                                fontSize='6'>
                                9
                            </text>
                        </g>

                        <g>
                            {animatedPercentage >= 95 ? (
                                <circle
                                    cx='89.3'
                                    cy='43'
                                    r='1'
                                    fill='#FFFFFF'
                                />
                            ) : null}
                            <text
                                x='78'
                                y='45'
                                textAnchor='middle'
                                fill='#000000'
                                fontSize='6'>
                                12+
                            </text>
                        </g>
                    </svg>
                </div>
            </div>
            <div className={styles.footer}>
                <p className={styles.footer_label}>{uvIndex} uv</p>
            </div>
        </div>
    )
}
