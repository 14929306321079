import { useForm, useSession } from '@/core/hooks'
import { SignInForm } from '../../components'
import { validateSignIn } from '../../validators'
import { AuthStep, ISignInForm } from '../../typing'
import { useSocialAuth } from '../../hooks'
import { SocialButton } from '@/core/components'
import { signInReq } from '../../api'
import { useState } from 'react'
import { Divider } from 'antd'
import * as Sentry from '@sentry/react'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { FacebookSignInWidget } from '../facebook-sign-in'

interface IProps {
    changeStep: (val: AuthStep) => void
}
export const SignInWidget = ({ changeStep }: IProps) => {
    const { t } = useTranslation()
    const { signInByGoogle, getSessionByFacebook } = useSocialAuth()
    const { saveSession } = useSession()
    const { values, errors, setField, onSubmit } = useForm({}, validateSignIn)
    const [error, setError] = useState(null)

    const submit = async () => {
        try {
            const { data } = await signInReq({ ...values, deviceName: 'pc' })
            saveSession(data)
        } catch (error: any) {
            setError(t('errorEmailPassword'))
            Sentry.captureException(error)
        }
    }
    const onChangeField = (key: keyof ISignInForm, val: any) => {
        setField(key, val)
        setError(null)
    }

    return (
        <div className={styles.container}>
            <div className={styles.containerHeader}>
                <p className={styles.title}>
                    {t('titleSignInStart')}
                    <br />
                    {t('titleSignInEnd')}
                </p>
            </div>
            <div className={styles.form}>
                <SignInForm
                    errorApi={error}
                    errors={errors}
                    values={values}
                    onChange={onChangeField}
                    submit={() => onSubmit(submit)}
                />
            </div>

            <p className={styles.register}>
                {t('haventAccount')}
                <span
                    onClick={() => changeStep(AuthStep.Register)}
                    className={styles.labelRegister}>
                    {t('createAccount')}
                </span>
            </p>

            <div className={styles.social}>
                <div className={styles.separator}>
                    <Divider
                        style={{
                            borderColor: '#EBF0FE',
                            width: '100%',
                            marginTop: 24,
                            marginBottom: 24,
                        }}>
                        <p
                            style={{
                                color: '#262933',
                                fontSize: 12,
                                lineHeight: '16px',
                                fontWeight: 400,
                            }}>
                            Or
                        </p>
                    </Divider>
                </div>

                <div className={styles.rowBtn}>
                    <SocialButton
                        className={styles.social_btn}
                        onClick={signInByGoogle}
                        label={t('signInGoogle')}
                        socialName='google'
                    />

                    <FacebookSignInWidget
                        keyLabel='signInFacebook'
                        classname={styles.social_btn}
                        getSession={getSessionByFacebook}
                    />
                </div>
            </div>
        </div>
    )
}
