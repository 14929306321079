import { CountriesTagsWidget } from '@/core/widgets'
import { LayoutSettings } from '@/modules/notifications/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    isEdit: boolean
    onChange: (val: string) => void
    subscribeCountries: string[]
    error?: string
    containerClassName?: string
    titleClassName?: string
}
export const ChooseFallowCountries = ({
    isEdit,
    subscribeCountries,
    onChange,
    error,
    containerClassName,
    titleClassName,
}: IProps) => {
    const { t } = useTranslation()
    return (
        <LayoutSettings
            className={containerClassName}
            title={`${t('followCountries')} *`}
            titleClassName={titleClassName}>
            <CountriesTagsWidget
                onChangeList={onChange}
                disabled={isEdit}
                values={subscribeCountries}
                error={error}
                withDescription={true}
            />
        </LayoutSettings>
    )
}
