import conflicts_illustration from '@/assets/images/conflicts_illustration.png'
import social_illustration from '@/assets/images/social_illustration.png'
import health_illustration from '@/assets/images/health_illustration.png'
import climate_illustration from '@/assets/images/climate_illustration.png'
import requirements_illustration from '@/assets/images/entry_illustration.png'
import legenda_illustration from '@/assets/images/legenda_illustration.png'
import map_illustration from '@/assets/images/map_illustration.png'
import chat_bot_illustration from '@/assets/images/chat_bot_illustration.png'
import profile_illustration from '@/assets/images/profile_illustration.png'

import women_illustration from '@/assets/images/women_illustration.png'
import lgbtq_illustration from '@/assets/images/LGBTQ_illustration.png'
import { CategoryRisks } from '@/core/typing'
export interface IMobileStep {
    title: string
    illustarionPath: string
    content: string
    key: string
}

export const mobileStepsConfig: IMobileStep[] = [
    {
        title: 'conflicts',
        illustarionPath: conflicts_illustration,
        content: 'conflictsInstruction',
        key: CategoryRisks.Conflicts,
    },
    {
        title: 'legend',
        illustarionPath: legenda_illustration,
        content: 'legendInstruction',
        key: 'legend',
    },
    {
        title: 'map',
        illustarionPath: map_illustration,
        content: 'mapInstructions',
        key: 'map',
    },
    {
        title: 'social',
        illustarionPath: social_illustration,
        content: 'socialIstruction',
        key: CategoryRisks.Social,
    },
    {
        title: 'medical',
        illustarionPath: health_illustration,
        content: 'healthInstruction',
        key: CategoryRisks.Medical,
    },
    {
        title: 'climate',
        illustarionPath: climate_illustration,
        content: 'climateInstruction',
        key: CategoryRisks.Climate,
    },
    {
        title: 'requirements',
        illustarionPath: requirements_illustration,
        content: 'requirementsInstruction',
        key: CategoryRisks.Requirements,
    },
    {
        title: 'women',
        illustarionPath: women_illustration,
        content: 'womenInstruction',
        key: CategoryRisks.Women,
    },
    {
        title: 'LGBTQ',
        illustarionPath: lgbtq_illustration,
        content: 'LGBTInstruction',
        key: CategoryRisks.LGBTQ,
    },
    {
        title: 'chatBot',
        illustarionPath: chat_bot_illustration,
        content: 'chatBotInstruction',
        key: 'chat-bot',
    },
    {
        title: 'personalAccount',
        illustarionPath: profile_illustration,
        content: 'accountInstruction',
        key: 'account',
    },
]
