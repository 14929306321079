import { useState } from 'react'
import { AuthStep } from '../typing'
import { managePasswordConfig } from '../config/manage-password.config'
import { ManagePaswordLayout } from '../components'
import { useNavigate } from 'react-router-dom'

interface StateStep {
    name: AuthStep
    params?: any
}
export const ManagePasswordPage = () => {
    const navigate = useNavigate()
    const [step, setStep] = useState<StateStep>({
        name: AuthStep.ForgotPassword,
    })
    const currentStep = managePasswordConfig[step.name]

    const Component = currentStep?.component

    const goBack = () => {
        switch (step.name) {
            case AuthStep.ForgotPassword:
                return navigate('/auth', {
                    state: { step: AuthStep.Login },
                })
            case AuthStep.SendCode:
                return setStep({ name: AuthStep.ForgotPassword })
            case AuthStep.NewPassword:
                return navigate('/auth', {
                    state: { step: AuthStep.Login },
                })
        }
    }

    return (
        <ManagePaswordLayout
            goBackName={step.name}
            onGoBack={goBack}
            component={<Component changeStep={setStep} step={step} />}
        />
    )
}
