import { AccountTabKey } from '@/modules/user/typing/enums'
import styles from './styles.module.css'
import { useAccountStore } from '@/store'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { useTranslation } from 'react-i18next'

interface IProps {
    activeTab: AccountTabKey
    onClickTab: (key: AccountTabKey) => void
}
export const TabBarAtom = ({ onClickTab, activeTab }: IProps) => {
    const { t } = useTranslation()
    const { account } = useAccountStore()
    return (
        <div
            className={styles.container}
            data-testid='mobile-account-container'>
            <div
                onClick={() => onClickTab(AccountTabKey.Profile)}
                className={`${styles.item_tab} ${activeTab === AccountTabKey.Profile ? styles.active_tab : ''}`}>
                <p
                    className={`${styles.label_tab} ${activeTab === AccountTabKey.Profile ? styles.active_label : ''}`}>
                    {t('profile')}
                </p>
            </div>
            <div
                onClick={() => onClickTab(AccountTabKey.Notifications)}
                className={`${styles.item_tab} ${activeTab === AccountTabKey.Notifications ? styles.active_tab : ''}`}>
                <p
                    className={`${styles.label_tab} ${activeTab === AccountTabKey.Notifications ? styles.active_label : ''}`}>
                    {t('notifications')}
                </p>
            </div>

            {account?.subscription?.type === SubscriptionType.Premium ||
            account?.subscription?.type === SubscriptionType.Premium_3m ? (
                <div
                    onClick={() => onClickTab(AccountTabKey.ChatBot)}
                    className={`${styles.item_tab} ${activeTab === AccountTabKey.ChatBot ? styles.active_tab : ''}`}>
                    <p
                        className={`${styles.label_tab} ${activeTab === AccountTabKey.ChatBot ? styles.active_label : ''}`}>
                        {t('chatBotHistory')}
                    </p>
                </div>
            ) : null}

            <div
                onClick={() => onClickTab(AccountTabKey.Subscribe)}
                className={`${styles.item_tab} ${activeTab === AccountTabKey.Subscribe ? styles.active_tab : ''}`}>
                <p
                    className={`${styles.label_tab} ${activeTab === AccountTabKey.Subscribe ? styles.active_label : ''}`}>
                    {t('subscription')}
                </p>
            </div>
        </div>
    )
}
