import { PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IProps {
    isEdit: boolean
    onChangeEdit: (val: boolean) => void
    save: () => void
    cancel: () => void
    isChangePassword?: boolean
}
export const MobileTabFooterAtom = ({
    isEdit,
    onChangeEdit,
    save,
    cancel,
    isChangePassword = false,
}: IProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const changePassword = () =>
        navigate('/change/password', { state: { isChange: true } })

    return (
        <div className={styles.container}>
            <div className={`${styles.btn_group}`}>
                <PrimaryButton
                    className={`${styles.btn_cancel} ${isEdit ? styles.show : styles.hide}`}
                    label={t('cancel')}
                    labelStyle={{
                        color: '#262933',
                    }}
                    onClick={cancel}
                />

                <PrimaryButton
                    className={`${isEdit ? styles.btn_save : styles.edit_btn}`}
                    label={isEdit ? t('save') : t('edit')}
                    onClick={() => (!isEdit ? onChangeEdit(true) : save())}
                />
            </div>

            {isChangePassword ? (
                <PrimaryButton
                    label={t('changePassword')}
                    onClick={changePassword}
                    labelStyle={{
                        color: '#262933',
                    }}
                    className={`${isEdit ? styles.hidden : styles.change_password}`}
                />
            ) : null}
        </div>
    )
}
