import { SettingsNotiificationsWidget } from '@/modules/notifications/widgets'
import { FooterPrivacy } from '../../components/footer-privacy'
import { AccountWidget } from '../../widgets'
import {
    UserSubscribeRedesignWidget,
    UserSubscribeWidget,
} from '@/modules/subscribe/widgets'
import { Subscribe } from '@/modules/subscribe/pages'
import { AccountHeader } from '../../components/account-header'
import styles from './styles.module.css'
import { MobileAccountTabs } from '../../components'
import { ChatBotSettingsWidget } from '@/modules/chat-bot/widgets'
import { useAccountStore } from '@/store'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { useEventsListener } from '@/core/hooks'
import { useMemo, useState } from 'react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { Icon, LogoutModal, PrimaryButton } from '@/core/components'
import { useNavigate } from 'react-router-dom'
import { createStyleSheet } from '@/core/helpers'
import { useAccount } from '../../hooks'

export const AccountPage = () => {
    const [isShow, setIsShow] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const close = () => setOpenModal(false)

    const { logOut } = useAccount()

    const changePassword = () =>
        navigate('/change/password', { state: { isChange: true } })

    useEventsListener(
        'account',
        (data: any) => {
            setIsShow(data?.isShow)
        },
        [],
    )
    const { account } = useAccountStore()

    const missingEmail = account && !account?.email && isShow

    const notUseEmail = useMemo(() => {
        if (missingEmail) {
            return message.warning(t('infoUseEmail'))
        }
        return null
    }, [account, isShow])

    if (!isShow) {
        return null
    }
    return (
        <div
            style={{
                height: '100dvh',
                zIndex: 999,
                top: 0,
                position: 'absolute',
                width: '100dvw',
            }}>
            <>{notUseEmail}</>
            <div className={styles.layout}>
                <AccountHeader />
                <MobileAccountTabs />

                <div className={styles.content}>
                    <div className={styles.forms_container}>
                        <AccountWidget />
                        <SettingsNotiificationsWidget />
                        {account?.subscription?.type ===
                            SubscriptionType.Premium ||
                        account?.subscription?.type ===
                            SubscriptionType.Premium_3m ? (
                            <ChatBotSettingsWidget />
                        ) : null}

                        <UserSubscribeWidget />

                        <FooterPrivacy style={{ marginTop: 20 }} />
                    </div>

                    <div className={styles.right_container}>
                        <div className={styles.btn_group}>
                            <PrimaryButton
                                label={t('changePassword')}
                                onClick={changePassword}
                                className={styles.change_password_btn}
                                labelStyle={inlineStyles.label_btn}
                            />
                            <PrimaryButton
                                label={t('logout')}
                                rightIcon={
                                    <Icon
                                        name='logout-1'
                                        size={16}
                                        color='#262933'
                                        style={{ transform: 'rotate(180deg)' }}
                                    />
                                }
                                onClick={() => setOpenModal(true)}
                                className={styles.logout}
                                labelStyle={inlineStyles.label_btn}
                            />
                        </div>
                        <UserSubscribeRedesignWidget />
                    </div>
                </div>
            </div>
            <Subscribe />
            <LogoutModal isOpen={openModal} close={close} confirm={logOut} />
        </div>
    )
}

const inlineStyles = createStyleSheet({
    label_btn: {
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 20,
        color: '#262933',
    },
})
