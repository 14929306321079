import { countriesConfig } from '@/core/widgets/country-autocomplete/countries.config'
import styles from './styles.module.css'

interface IProps {
    listCode: string[]
}
export const CountriesFor = ({ listCode }: IProps) => {
    const getCountriesByContinent = () => {
        if (!listCode) return
        const countries = countriesConfig
            .filter(it => listCode.includes(it.alpha2))
            .map(el => el.name)

        return countries
    }

    const countries = getCountriesByContinent()

    if (!listCode) return null
    return (
        <div className={styles.countries}>
            {countries.map(it => (
                <div className={styles.tag}>
                    <p className={styles.tag_label}>{it}</p>
                </div>
            ))}
        </div>
    )
}
