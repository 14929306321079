import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { Drawer } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { HeaderList, NotificationsFullList } from '../../components'
import { useNotifications } from '../../hooks'
import styles from './styles.module.css'
import {
    getAllUnreadNotificationsReq,
    markAllNotificationsReadReq,
    readNotificationByPassesIdReq,
} from '../../api'
import _ from 'lodash'
import { appEvents } from '@/core/events'
import { useCountUnreadNotificationsStore } from '@/store'
import { INotification } from '../../typing'
import * as Sentry from '@sentry/react'

export const FullNotiicationsWidget = () => {
    const { width: widthWindow } = useResizeWindow()
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [scrollPosition, setScrollPosition] = useState(0)
    const { setCount } = useCountUnreadNotificationsStore()
    const [isLoading, setIsLoading] = useState(false)
    const [oneNotif, setOneNotif] = useState<INotification>()
    const [isUnread, setIsUnread] = useState(false)
    const { notifications, setParams, loadNext, resetList, loading } =
        useNotifications()

    useEventsListener('notifications', (data: any) => {
        setOpen(data.isShow)
        setOneNotif(data?.notification)
    })

    const closeDrawer = () => {
        setOpen(false)
        setPage(1)
        setScrollPosition(0)
    }

    const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100,
        )
        setScrollPosition(position)
    }

    const readNotifications = async (readOneId?: string) => {
        try {
            if (readOneId) {
                await readNotificationByPassesIdReq({ ids: [readOneId] })
                const { data } = await getAllUnreadNotificationsReq()
                setCount(data)
            } else {
                const sliceLast = notifications.slice(-15).map(it => it.id)
                const resp = await readNotificationByPassesIdReq({
                    ids: sliceLast,
                })

                if (resp.status === 201) {
                    const { data } = await getAllUnreadNotificationsReq()
                    setIsUnread(!!data)
                    setCount(data)
                }
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    useEffect(() => {
        if (scrollPosition === 99) {
            setPage(page + 1)
        }
    }, [scrollPosition])

    useEffect(() => {
        if (page === 1) {
            setParams({ sortField: 'createdAt', sort: 'DESC', limit: 15 })
        } else {
            loadNext(page)
        }
    }, [open, page])

    useEffect(() => {
        if (loading) return
        if (open) {
            readNotifications(oneNotif?.id)
        }
    }, [page, open, oneNotif, loading])

    useEffect(() => {
        if (!open) {
            resetList()
            setPage(1)
            setScrollPosition(0)
            return
        }
    }, [page, open, oneNotif])

    const getOneNotification = useCallback(() => {
        if (oneNotif) {
            return [oneNotif]
        } else {
            return notifications
        }
    }, [oneNotif, notifications])

    const readAllNotifications = async () => {
        setIsLoading(true)
        try {
            await markAllNotificationsReadReq()
            appEvents.emit('resetNotification', {})
            setParams({ sortField: 'createdAt', sort: 'DESC', page: 1 })
        } catch (error) {
            Sentry.captureException(error)
        } finally {
            setIsLoading(false)
        }
    }
    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 767:
                return '100dvw'
            case widthWindow < 1024:
                return 'calc(100dvw - 16px)'

            default:
                return '720px'
        }
    }

    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            className={styles.notificationsDrawer}
            styles={{
                body: {
                    padding: 0,
                    overflow: 'hidden',
                },
                wrapper: {
                    width: drawerWidth(),
                    borderRadius: 24,
                    margin: widthWindow > 767 ? '8.5px 8px' : 0,
                    boxShadow: 'none',
                },
            }}
            onClose={() => setOpen(false)}>
            <div className={styles.notifications_container}>
                <HeaderList
                    isUnread={isUnread}
                    readAll={readAllNotifications}
                    isLoadingAllRead={isLoading}
                    isOne={!!oneNotif}
                    close={closeDrawer}
                    notification={getOneNotification()[0]}
                />

                <NotificationsFullList
                    isOne={!!oneNotif}
                    items={getOneNotification()}
                    handleScroll={handleScroll}
                />
            </div>
        </Drawer>
    )
}
