import { TableColumnsType } from 'antd'
import { IDataDayTable } from '../../typing/interface'
import { CellAtom, FirstCellHeaderAtom, LabelRow } from './atoms'
import { HeaderCellAtom } from './atoms/header-cell.atom'
import { t } from 'i18next'

export const columns = (
    widthFirstColumn: number,
): TableColumnsType<IDataDayTable> => [
    {
        title: <FirstCellHeaderAtom txt={t('detailedForecast')} />,
        key: 'label',
        width: widthFirstColumn,
        fixed: 'left',
        align: 'left',
        render: val => <LabelRow label={val.label} iconName={val.iconName} />,
    },
    {
        title: (
            <HeaderCellAtom
                txt={t('night')}
                labelStyle={{
                    fontWeight: '500',
                    fontSize: 14,
                    color: '#262933',
                }}
            />
        ),
        align: 'center',
        children: [
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='2:00AM'
                    />
                ),

                key: 'night2',
                width: 100,
                align: 'center',
                render: val => <CellAtom keyField='night2' value={val} />,
            },
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='5:00AM'
                    />
                ),
                render: val => <CellAtom keyField='night5' value={val} />,
                key: 'night5',
                width: 100,
                align: 'center',
            },
        ],
    },
    {
        title: (
            <HeaderCellAtom
                txt={t('morning')}
                labelStyle={{
                    fontWeight: '500',
                    fontSize: 14,
                    color: '#262933',
                }}
            />
        ),
        align: 'center',
        children: [
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='8:00AM'
                    />
                ),
                render: val => <CellAtom keyField='morning8' value={val} />,
                key: 'morning8',
                width: 100,
                align: 'center',
            },
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='11:00AM'
                    />
                ),

                render: val => <CellAtom keyField='morning11' value={val} />,
                key: 'morning11',
                width: 100,
                align: 'center',
            },
        ],
    },
    {
        title: (
            <HeaderCellAtom
                txt={t('day')}
                labelStyle={{
                    fontWeight: '500',
                    fontSize: 14,
                    color: '#262933',
                }}
            />
        ),
        align: 'center',
        children: [
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='2:00PM'
                    />
                ),
                render: val => <CellAtom keyField='day2' value={val} />,
                key: 'day2',
                width: 100,
                align: 'center',
            },
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='5:00PM'
                    />
                ),
                render: val => <CellAtom keyField='day5' value={val} />,
                key: 'day5',
                width: 100,
                align: 'center',
            },
        ],
    },
    {
        title: (
            <HeaderCellAtom
                txt={t('evening')}
                labelStyle={{
                    fontWeight: '500',
                    fontSize: 14,
                    color: '#262933',
                }}
            />
        ),
        align: 'center',
        children: [
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='8:00PM'
                    />
                ),

                render: val => <CellAtom keyField='evening8' value={val} />,
                key: 'evening8',
                width: 100,
                align: 'center',
            },
            {
                title: (
                    <HeaderCellAtom
                        styleContainer={{ margin: '-7px' }}
                        labelStyle={{
                            color: '#868992',
                            fontSize: 12,
                            fontWeight: '400',
                        }}
                        txt='11:00PM'
                    />
                ),
                render: val => <CellAtom keyField='evening11' value={val} />,
                key: 'evening11',
                width: 100,
                align: 'center',
            },
        ],
    },
]
