import styles from './styles.module.css'
import { CSSProperties } from 'react'

interface IProps {
    cardPicture: string
    labelCard: string
    onClickCard: () => void
    style?: CSSProperties
}

export const CardFaq = ({
    cardPicture,
    labelCard,
    onClickCard,
    style,
}: IProps) => {
    return (
        <div onClick={onClickCard} className={styles.card} style={style}>
            <img src={cardPicture} className={styles.img_card} />
            <p className={styles.label}>{labelCard}</p>
        </div>
    )
}
