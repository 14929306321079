import { createStyleSheet } from '@/core/helpers'
import OtpInput from 'react-otp-input'
import { InputHTMLAttributes } from 'react'
import './styles.css'
import _ from 'lodash'

interface FormControllProps {
    onChangeVal: (val: string) => void
    value: string
    error?: string
    mb?: number
}
export const FormControlCode = (props: FormControllProps) => {
    return (
        <div
            style={{
                ...styles.container,
                marginBottom: _.defaultTo(props.mb, 0),
            }}>
            <OtpInput
                skipDefaultStyles
                shouldAutoFocus
                value={props.value}
                onChange={props.onChangeVal}
                numInputs={4}
                inputStyle={`code-input ${props.error ? 'error_field' : ''} ${props.value?.length >= 4 ? 'code-value' : ''}`}
                inputType='number'
                placeholder='––––'
                renderSeparator={<span style={{ width: 16 }} />}
                renderInput={(
                    input: InputHTMLAttributes<HTMLInputElement>,
                    index: number,
                ) => <input id={`code cell ${index}`} {...input} key={index} />}
            />
            {props.error ? <p className='error'>{props.error}</p> : null}
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        position: 'relative',
    },
})
