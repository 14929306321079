import { Icon } from '@/core/components'
import styles from './styles.module.css'

interface IProps {
    onlyIcon?: boolean
    onClick: () => void
}
export const FAQBtn = ({ onlyIcon, onClick }: IProps) => {
    return (
        <div
            onClick={onClick}
            className={styles.container}
            style={{ padding: onlyIcon ? '0px 12px' : '0px 25.5px' }}>
            <Icon name='music-play' size={16} color='#262933' />
            {onlyIcon ? null : <p>FAQ</p>}
        </div>
    )
}
