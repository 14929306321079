import { SelectCountry } from '@/core/widgets'
import styles from './styles.module.css'
import { PrimaryButton } from '@/core/components'
import { useAccountStore } from '@/store'
import { validateRequirements } from '../../validators'
import { useForm } from '@/core/hooks'
import { appEvents } from '@/core/events'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ISearchRequirements {
    from: string
    to: string
    citizenship?: string
}

interface IProps {
    clasNameContainer?: string
}
export const SearchRequrementsWidget = ({
    clasNameContainer = styles.container,
}: IProps) => {
    const { t } = useTranslation()
    const { account } = useAccountStore()
    const existSubscribeActive = !account?.subscription
    const [counriesName, setCountriesName] = useState<{
        fromName: string
        toName: string
    }>()

    const { setField, errors, values, onSubmit } = useForm<ISearchRequirements>(
        {},
        validateRequirements,
    )
    const onChange = (key: keyof ISearchRequirements, val: string) => {
        setField(key, val)
    }

    const submit = async () => {
        try {
            appEvents.emit('requrements', {
                isShow: true,
                data: {
                    from: { code: values.from, name: counriesName?.fromName },
                    to: { code: values.to, name: counriesName?.toName },
                    citizenship: values?.citizenship,
                },
            })
        } catch (error) {
            Sentry.captureException(error)
        }
    }
    return (
        <div className={clasNameContainer}>
            <div className={styles.header}>
                <p className={styles.title}>{t('requirements')}</p>
                <p className={styles.description}>
                    {t('searchRequirementsDescription')}
                </p>
            </div>

            <div className={styles.form}>
                <div className={styles.formRow}>
                    <SelectCountry
                        valueHidden={!values?.from}
                        onSelectCountry={val => {
                            onChange('from', val?.countryCode)
                            setCountriesName({
                                ...counriesName,
                                fromName: val?.locationName,
                            })
                        }}
                        label={`${t('fromCountry')} *`}
                        error={errors.from}
                        chooseCountries={[values?.to]}
                        id='select-country'
                        dropdownClassName='entry_req_country_autocomplete'
                        containerClassName='entry_req'
                    />

                    <SelectCountry
                        valueHidden={!values?.to}
                        onSelectCountry={val => {
                            onChange('to', val?.countryCode)
                            setCountriesName({
                                ...counriesName,
                                toName: val?.locationName,
                            })
                        }}
                        label={`${t('toCountry')} *`}
                        error={errors.to}
                        chooseCountries={[values?.from]}
                        id='select-country'
                        dropdownClassName='entry_req_country_autocomplete'
                        containerClassName='entry_req'
                    />
                </div>

                <div className={styles.formRow}>
                    <SelectCountry
                        onSelectCountry={val => {
                            onChange('citizenship', val?.countryCode)
                        }}
                        label={t('citіzenship')}
                        id='select-country'
                        containerClassName='entry_req'
                    />
                </div>

                <div className={styles.buttonBlock}>
                    <PrimaryButton
                        disabled={
                            existSubscribeActive || !values.from || !values.to
                        }
                        className={styles.btn}
                        labelStyle={{ fontSize: 14, lineHeight: 20 }}
                        label={t('search')}
                        onClick={() => onSubmit(submit)}
                    />
                </div>
            </div>
        </div>
    )
}
