import { PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'

import {
    getUserNotificationsSettingsReq,
    updateNotificationsSettingsReq,
} from '../../api'
import { TypeCategoryNotifications, TypeNotification } from '../../typing'

import { message } from 'antd'
import _ from 'lodash'
import { MobileTabFooterAtom } from '@/modules/user/components/mobile-account-tabs/atoms'
import * as Sentry from '@sentry/react'
import {
    CategoryNotifications,
    ChooseFallowCountries,
    TypeSendNotification,
} from '../../components'
import { useTranslation } from 'react-i18next'
import { BlurSubscription } from '@/modules/subscribe/components'
import { useResizeWindow } from '@/core/hooks'

export const SettingsNotiificationsWidget = () => {
    const { t } = useTranslation()
    const [isEdit, setIsEdit] = useState(false)
    const [method, setMethod] = useState<TypeNotification[]>([])
    const [countries, setCountries] = useState([])
    const [types, setTypes] = useState<TypeCategoryNotifications[]>([])
    const { width: windowWidth } = useResizeWindow()
    const getNotificationsSettings = async () => {
        try {
            const { data } = await getUserNotificationsSettingsReq()
            setMethod(data?.method as TypeNotification[])
            setTypes(
                data?.type.filter(it =>
                    _.values(TypeCategoryNotifications).includes(
                        it as TypeCategoryNotifications,
                    ),
                ) as TypeCategoryNotifications[],
            )
            setCountries(data?.country)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    useEffect(() => {
        getNotificationsSettings()
    }, [])

    const onToggleEdit = (val: boolean) => {
        setIsEdit(val)
    }

    const updateNotificationsSettings = async () => {
        try {
            await updateNotificationsSettingsReq({
                method,
                type: types,
                country: countries,
            })

            message.success('Settings update successfully')
            await getNotificationsSettings()
            setIsEdit(false)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const onChangeMethod = (val: TypeNotification) => {
        if (method?.includes(val)) {
            setMethod(prev => prev.filter(it => it !== val))
        } else {
            setMethod([...method, val])
        }
    }

    const onChangeTypes = (val: TypeCategoryNotifications) => {
        if (types?.includes(val)) {
            setTypes(prev => prev.filter(it => it !== val))
        } else {
            setTypes([...types, val])
        }
    }

    const onChangeCountries = (val: string) => {
        if (countries.includes(val)) {
            setCountries(prev => prev.filter(it => it !== val))
        } else {
            setCountries([...countries, val])
        }
    }

    const resetForm = () => {
        onToggleEdit(false)
        getNotificationsSettings()
    }

    return (
        <div className={styles.container} id='notification-settings'>
            {windowWidth > 767 ? <BlurSubscription /> : null}
            <div className={styles.header_form}>
                <p className={styles.label_form}>{t('notifications')}</p>

                <div className={styles.btn_group}>
                    <PrimaryButton
                        label={t('cancel')}
                        onClick={resetForm}
                        id='notiffication-cancel-button'
                        className={`${styles.btn_cancel} ${!isEdit ? styles.show : styles.hide}`}
                        labelStyle={{ color: '#262933' }}
                    />

                    <PrimaryButton
                        label={isEdit ? t('save') : t('edit')}
                        onClick={() =>
                            !isEdit
                                ? onToggleEdit(true)
                                : updateNotificationsSettings()
                        }
                        className={styles.btn_save}
                        id='notification-save-or-edit-button'
                    />
                </div>
            </div>
            <div className={styles.settings_box}>
                <div className={styles.col1}>
                    <CategoryNotifications
                        containerClassName={styles.layout_setting}
                        disabled={!isEdit}
                        types={types}
                        onChange={onChangeTypes}
                    />
                </div>
                <div className={styles.col2}>
                    <ChooseFallowCountries
                        containerClassName={styles.layout_setting}
                        subscribeCountries={countries}
                        isEdit={!isEdit}
                        onChange={onChangeCountries}
                    />

                    <TypeSendNotification
                        className={styles.layout_setting}
                        methods={method}
                        disabled={!isEdit}
                        onChange={onChangeMethod}
                    />
                </div>
            </div>

            <MobileTabFooterAtom
                cancel={resetForm}
                onChangeEdit={onToggleEdit}
                isEdit={isEdit}
                save={updateNotificationsSettings}
            />
        </div>
    )
}
