interface IWeatherLocation {
    lat: number
    lng: number
    city: string
    region: string
    country: string
}

import { create } from 'zustand'

interface StoreWeatherLocationState {
    weatherLocation: IWeatherLocation
    setWeatherLocation: (location: IWeatherLocation) => void
    clearweatherLocation: () => void
}

export const useWeatherLocationStore = create<StoreWeatherLocationState>(
    set => ({
        weatherLocation: null,
        setWeatherLocation: data => set({ weatherLocation: data }),
        clearweatherLocation: () => set({ weatherLocation: null }),
    }),
)
