import { CardFaq, FrequentlyAsked } from '../../components'
import noteAbout from '@/assets/images/note-about.svg'
import questionsImg from '@/assets/images/questions.svg'
import styles from './styles.module.css'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { useState } from 'react'
import { Drawer } from 'antd'
import { HeaderChat } from '@/modules/chat-bot/components'
import _ from 'lodash'
import { appEvents } from '@/core/events'
import { useTranslation } from 'react-i18next'
import { FullSubscribe } from '@/modules/subscribe/components'

export const FAQsWidget = () => {
    const { t } = useTranslation()
    const { width: widthWindow } = useResizeWindow()
    const [open, setOpen] = useState(false)
    const [showAsked, setShowAsked] = useState(false)

    const closeDrawer = () => {
        setOpen(false)
        setTimeout(() => setShowAsked(false), 200)
    }

    useEventsListener('faqs', (data: any) => {
        setOpen(data.isShow)
    })

    const goTariffsPlan = () => {
        setOpen(false)
        appEvents.emit('subsribe', {
            isShow: true,
            method: 'buy',
            isUpdate: true,
        })
    }
    const startIntro = () => {
        setOpen(false)
        appEvents.emit('intro', { isShow: true, onlyIntro: true })
    }
    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 1024:
                return '100dvw'
            case showAsked:
                return '100dvh'

            default:
                return '390px'
        }
    }

    const isMobile = widthWindow < 768

    const subTitleContent = isMobile ? (
        <p className={styles.subTitle}>{t('subtitleFAQ')}</p>
    ) : (
        <p className={styles.subTitle}>{t('subtitleFAQ')}</p>
    )
    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            styles={{
                body: {
                    overflow: 'hidden',
                    padding: 0,
                    paddingBottom: 80,
                    paddingLeft: 1,
                    borderRadius: 24,
                    backdropFilter: ' blur(24px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
                content: {
                    borderRadius: 24,
                    backgroundColor: 'inherit',
                },
                wrapper: {
                    width: drawerWidth(),
                    borderRadius: 24,
                    backgroundColor: 'inherit',
                    margin: widthWindow > 767 ? '8.5px 8px' : 0,
                    boxShadow: 'none',
                },
            }}
            onClose={closeDrawer}>
            <HeaderChat closeChat={closeDrawer} />
            <div className={styles.container}>
                {showAsked ? (
                    <FrequentlyAsked />
                ) : (
                    <>
                        <p className={styles.title}>{t('titleTravy')}</p>
                        {subTitleContent}
                        <div className={styles.cardContainer}>
                            <CardFaq
                                cardPicture={noteAbout}
                                labelCard={t('aboutPlatform')}
                                onClickCard={startIntro}
                            />
                            <CardFaq
                                cardPicture={questionsImg}
                                labelCard={t('titleFAQ')}
                                onClickCard={() => setShowAsked(true)}
                            />

                            <FullSubscribe onClick={goTariffsPlan} />
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    )
}
