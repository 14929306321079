import { appEvents } from '@/core/events'
import { PrimaryButton } from '../buttons'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { useEventsListener } from '@/core/hooks'
import { Modal } from 'antd'
import logo from '@/assets/images/guido_bot.png'
import { Icon } from '../icons'
import { useTranslation } from 'react-i18next'

export const GreetingTravy = () => {
    const { t } = useTranslation()
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        if (isShow) {
            localStorage.setItem('LEGEND', JSON.stringify(true))
        }
    }, [isShow])

    useEventsListener('greeting', data => {
        setIsShow(data.isShow)
    })

    const startIntro = () => {
        appEvents.emit('greeting', { isShow: false })
        appEvents.emit('intro', { isShow: true })
    }

    const close = () => {
        appEvents.emit('greeting', { isShow: false })
        appEvents.emit('settingsNotifications', { isShow: true })
    }

    return (
        <Modal
            styles={{ body: { width: '100%' } }}
            style={{ width: 300 }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={isShow}>
            <div className={styles.content_modal}>
                <div className={styles.header}>
                    <div className={styles.row_header}>
                        <img src={logo} style={{ width: 25, height: 32 }} />
                        <p className={styles.title}>{t('greetingTitle')}</p>
                    </div>
                    <Icon
                        onClick={close}
                        name='x'
                        size={18}
                        color='#868992'
                        className={styles.close_icon}
                    />
                </div>

                <p className={styles.content}>{t('greetingContent')}</p>

                <div className={styles.action_group}>
                    <div className={styles.btn_group}>
                        <PrimaryButton
                            className={styles.outline_btn}
                            labelStyle={{ fontSize: 14, color: '#262933' }}
                            onClick={close}
                            label={t('skip')}
                        />
                        <PrimaryButton
                            className={styles.primary_btn}
                            labelStyle={{ fontSize: 14, fontWeight: '500' }}
                            onClick={startIntro}
                            label={t('showTips')}
                            rightIcon={
                                <Icon
                                    name='arrow-right'
                                    size={16}
                                    color='#FFFFFF'
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
