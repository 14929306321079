import { Icon } from '@/core/components'
import { createStyleSheet } from '@/core/helpers'

export const LabelRow = ({
    label,
    iconName,
}: {
    label: string
    iconName: string
}) => {
    return (
        <div style={styles.container}>
            <Icon
                style={styles.icon}
                name={iconName}
                color='#0F0E0E'
                size={16}
            />
            <p>{label}</p>
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        position: 'relative',
        marginTop: -3,
        marginBottom: -3,
        paddingLeft: 30,
    },

    icon: {
        position: 'absolute',
        left: 0,
    },
})
