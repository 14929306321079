import { IMenu } from '@/core/components/menu/typing'
import { CategoryRisks, SubcategoryRisks } from '@/core/typing'

export const categoriesIconConfig: any = {
    [CategoryRisks.Owerview]: 'overview',
    [CategoryRisks.Conflicts]: 'huobi-token',
    [SubcategoryRisks.Geopolitical]: 'huobi-token',
    [SubcategoryRisks.InternalSocialTensions]: 'huobi-token',
    [SubcategoryRisks.PotentialDanger]: 'huobi-token',

    [CategoryRisks.Social]: 'profile-2user',
    [SubcategoryRisks.StandardOfLiving]: 'profile-2user',
    [SubcategoryRisks.WaterSupplies]: 'profile-2user',
    [SubcategoryRisks.HumanCapitalDevelopment]: 'profile-2user',

    [CategoryRisks.Medical]: 'heart-1',
    [SubcategoryRisks.MedicalSituation]: 'heart-1',
    [SubcategoryRisks.PandemicsAndEpidemics]: 'heart-1',

    [CategoryRisks.Climate]: 'flash',
    [SubcategoryRisks.Weather]: 'flash',
    [SubcategoryRisks.SeaLevelRise]: 'flash',

    [CategoryRisks.Requirements]: 'book',
    [CategoryRisks.LGBTQ]: 'lgbt',
    [CategoryRisks.Women]: 'women',
}

export const risksConfig: IMenu[] = [
    {
        label: 'conflicts',
        key: CategoryRisks.Conflicts,
        iconName: 'huobi-token',
        items: [
            {
                label: 'geoConflicts',
                key: SubcategoryRisks.Geopolitical,
            },
            {
                label: 'internalConflicts',
                key: SubcategoryRisks.InternalSocialTensions,
            },
            {
                label: 'dangerPotential',
                key: SubcategoryRisks.PotentialDanger,
            },
        ],
    },
    {
        label: 'social',
        key: CategoryRisks.Social,
        iconName: 'profile-2user',
        items: [
            {
                label: 'standardLiving',
                key: SubcategoryRisks.StandardOfLiving,
            },
            {
                label: 'foodDrinking',
                key: SubcategoryRisks.WaterSupplies,
            },
            {
                label: 'humanCapitalDev',
                key: SubcategoryRisks.HumanCapitalDevelopment,
            },
        ],
    },
    {
        label: 'medical',
        key: CategoryRisks.Medical,
        iconName: 'heart-1',
        items: [
            {
                label: 'medicalSituation',
                key: SubcategoryRisks.MedicalSituation,
            },
            {
                label: 'pandemics',
                key: SubcategoryRisks.PandemicsAndEpidemics,
            },
        ],
    },
    {
        label: 'climate',
        key: CategoryRisks.Climate,
        iconName: 'flash',
        items: [
            { label: 'weather', key: SubcategoryRisks.Weather },
            {
                label: 'seaLevel',
                key: SubcategoryRisks.SeaLevelRise,
            },
        ],
    },
    {
        label: 'requirements',
        key: CategoryRisks.Requirements,
        iconName: 'book',
    },
    {
        label: 'LGBTQ',
        key: CategoryRisks.LGBTQ,
        iconName: 'lgbt',
    },
    {
        label: 'women',
        key: CategoryRisks.Women,
        iconName: 'women',
    },
]

export const menuLabels: any = {
    [SubcategoryRisks.SeaLevelRise]: 'seaLevel',
    [SubcategoryRisks.Geopolitical]: 'geoConflicts',
    [SubcategoryRisks.InternalSocialTensions]: 'internalConflicts',
    [SubcategoryRisks.PotentialDanger]: 'dangerPotential',
    [SubcategoryRisks.StandardOfLiving]: 'standardLiving',
    [SubcategoryRisks.WaterSupplies]: 'foodDrinking',
    [SubcategoryRisks.HumanCapitalDevelopment]: 'humanCapitalDev',
    [SubcategoryRisks.MedicalSituation]: 'medicalSituation',
    [SubcategoryRisks.PandemicsAndEpidemics]: 'pandemics',
    [SubcategoryRisks.Weather]: 'weather',
    [CategoryRisks.Requirements]: 'requirements',
    [CategoryRisks.Climate]: 'climate',
    [CategoryRisks.Conflicts]: 'conflicts',
    [CategoryRisks.Social]: 'social',
    [CategoryRisks.Medical]: 'medical',
    [CategoryRisks.Owerview]: 'overview',
    [CategoryRisks.LGBTQ]: 'LGBTQ',
    [CategoryRisks.Women]: 'women',
}

export const pdfFileNames: any = {
    [SubcategoryRisks.SeaLevelRise]: 'sea-level',
    [SubcategoryRisks.Geopolitical]: 'geopolit-conflicts',
    [SubcategoryRisks.InternalSocialTensions]: 'internal-soc-tens',
    [SubcategoryRisks.PotentialDanger]: 'potent-danger',
    [SubcategoryRisks.StandardOfLiving]: 'stand-of-living',
    [SubcategoryRisks.WaterSupplies]: 'food-and-drink',
    [SubcategoryRisks.HumanCapitalDevelopment]: 'hum-capital-dev',
    [SubcategoryRisks.MedicalSituation]: 'med-sit',
    [SubcategoryRisks.PandemicsAndEpidemics]: 'pandem-and-epidem',
    [SubcategoryRisks.Weather]: 'weather',
    [CategoryRisks.Requirements]: 'entry-req',
    [CategoryRisks.Climate]: 'climate',
    [CategoryRisks.Conflicts]: 'conflicts',
    [CategoryRisks.Social]: 'social',
    [CategoryRisks.Medical]: 'medical',
    [CategoryRisks.Owerview]: 'overview',
    [CategoryRisks.LGBTQ]: 'lgbtq',
    [CategoryRisks.Women]: 'women',
}
