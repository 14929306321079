import { Icon, PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { updateNotificationsSettingsReq } from '../../api'
import { TypeCategoryNotifications, TypeNotification } from '../../typing'
import { Modal } from 'antd'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import {
    CategoryNotifications,
    ChooseFallowCountries,
    TypeSendNotification,
} from '../../components'
import { useAccountStore } from '@/store'
import { appEvents } from '@/core/events'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { useTranslation } from 'react-i18next'

export const SettingsNotiificationsModalWidget = () => {
    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const [method, setMethod] = useState<TypeNotification[]>([])
    const [countries, setCountries] = useState([])
    const [types, setTypes] = useState<TypeCategoryNotifications[]>([])
    const [error, setError] = useState('')

    const { account } = useAccountStore()
    const { width: widthWindow } = useResizeWindow()

    useEventsListener('settingsNotifications', data => setIsOpen(data.isShow))

    const close = () => {
        setIsOpen(false)
        appEvents.emit('settingsNotifications', { isShow: false })
    }

    const getNotificationsSettings = async () => {
        try {
            setMethod(_.values(TypeNotification))
            setTypes(_.values(TypeCategoryNotifications))
            setCountries(
                account?.location?.countryCode
                    ? [account?.location?.countryCode]
                    : [],
            )
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    useEffect(() => {
        getNotificationsSettings()
    }, [account])

    const updateNotificationsSettings = async () => {
        try {
            if (!_.isEmpty(countries)) {
                await updateNotificationsSettingsReq({
                    method,
                    type: types,
                    country: countries,
                })

                setIsOpen(false)
                appEvents.emit('settingsNotifications', { isShow: false })
            } else {
                setError(t('errorSelectCountry'))
                return
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const onChangeMethod = (val: TypeNotification) => {
        if (method?.includes(val)) {
            setMethod(prev => prev.filter(it => it !== val))
        } else {
            setMethod([...method, val])
        }
    }

    const onChangeTypes = (val: TypeCategoryNotifications) => {
        if (types?.includes(val)) {
            setTypes(prev => prev.filter(it => it !== val))
        } else {
            setTypes([...types, val])
        }
    }

    const onChangeCountries = (val: string) => {
        setError('')
        if (countries.includes(val)) {
            setCountries(prev => prev.filter(it => it !== val))
        } else {
            setCountries([...countries, val])
        }
    }

    const resetForm = () => {
        close()
    }

    const buttonLabel = () => {
        if (widthWindow <= 500) return `${t('confirm')}`
        return `${t('save')} & ${t('confirm')}`
    }

    const iconSize = () => {
        if (widthWindow <= 500) return 16
        return 18
    }

    return (
        <Modal
            transitionName=''
            maskTransitionName=''
            closeIcon={null}
            footer={null}
            open={isOpen}
            classNames={{ content: styles.container }}>
            <div className={styles.header_form}>
                <div className={styles.text_block_header_form}>
                    <p className={styles.label_form}>
                        {t('setupNotifications')}
                    </p>
                    <p className={styles.subLabel_form}>{t('editInAccount')}</p>
                </div>

                <div className={styles.close_container}>
                    <Icon
                        name='x'
                        className={styles.close}
                        size={18}
                        color='#868992'
                        onClick={resetForm}
                    />
                </div>
            </div>

            <div className={styles.settings_box}>
                <ChooseFallowCountries
                    subscribeCountries={countries}
                    isEdit={false}
                    onChange={onChangeCountries}
                    titleClassName={styles.setting_title}
                    error={error}
                />
                <div className={styles.divider}></div>

                <CategoryNotifications
                    titleClassName={styles.setting_title}
                    types={types}
                    onChange={onChangeTypes}
                />
                <TypeSendNotification
                    className={styles.last_setting_block}
                    titleClassName={styles.setting_title}
                    methods={method}
                    disabled={false}
                    onChange={onChangeMethod}
                />
            </div>

            <div className={styles.btn_group}>
                <PrimaryButton
                    label={buttonLabel()}
                    onClick={updateNotificationsSettings}
                    className={styles.btn_save}
                    rightIcon={
                        <Icon
                            name='tick-circle'
                            size={iconSize()}
                            color='#ffffff'
                            className={styles.btn_icon}
                        />
                    }
                />
            </div>
        </Modal>
    )
}
