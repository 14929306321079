import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { useState } from 'react'

interface IProps {
    idCard: string
    question: string
    answer: string
}
export const FaqQuestion = ({ question, answer }: IProps) => {
    const [active, setActive] = useState(false)

    return (
        <div className={styles.container}>
            <div
                className={styles.top_container}
                onClick={() => setActive(!active)}>
                <p className={styles.label}>{question}</p>
                <Icon
                    style={{
                        backgroundColor: active ? '#EBF0FE' : 'transparent',
                    }}
                    className={styles.icon_item}
                    name={active ? 'arrow-up' : 'arrow-down'}
                    size={18}
                    color='#292D32'
                    onClick={() => setActive(!active)}
                />
            </div>

            <div
                className={`${styles.answer}`}
                style={{ display: active ? 'flex' : 'none' }}>
                <p className={styles.answer_txt}>{answer}</p>
            </div>
        </div>
    )
}
