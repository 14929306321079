import { createStyleSheet } from '@/core/helpers'
import cssStyles from './styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    value: 'q' | 'm'
    onChange: (val: 'q' | 'm') => void
}
export const SwitchTarrifs = ({ onChange, value }: IProps) => {
    const { t } = useTranslation()
    return (
        <div style={styles.container}>
            <div onClick={() => onChange('q')} style={styles.switchItem}>
                <p
                    className={`${cssStyles.switchLabel} ${value === 'q' ? cssStyles.activeLabel : ''}`}>
                    {t('quaterly')}
                </p>
            </div>
            <p className={cssStyles.quaterly_economy}>{t('save')} 10%</p>

            <div onClick={() => onChange('m')} style={styles.switchItem}>
                <p
                    className={`${cssStyles.switchLabel} ${value === 'm' ? cssStyles.activeLabel : ''}`}>
                    {t('monthly')}
                </p>
            </div>
            <div
                style={{
                    ...styles.actionItem,
                    transform:
                        value === 'q' ? 'translateX(3%)' : 'translateX(97%)',
                }}
            />
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        borderRadius: '100px',
        position: 'relative',
        width: '262px',
        height: '48px',
        padding: '3px',
        background: '#FFFFFF',
    },
    switchItem: {
        width: '50%',
        padding: '10px 13.5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },

    actionItem: {
        position: 'absolute',
        top: 4,
        left: 0,
        width: '50%',
        height: 40,
        backgroundColor: '#262933',
        borderRadius: '120px',
        transition: 'transform 0.3s ease',
    },
})
