import { PrimaryButton } from '@/core/components/buttons'
import styl from './styles.module.css'
import { createStyleSheet } from '@/core/helpers'
import { useTranslation } from 'react-i18next'

interface IProps {
    goSignIn: () => void
    goSignUp: () => void
    avatar?: string
}

export const AuthGroupAtom = ({ goSignIn, goSignUp }: IProps) => {
    const { t } = useTranslation()

    return (
        <div className={styl.auth_group}>
            <PrimaryButton
                label={t('signIn')}
                onClick={goSignIn}
                className={styl.signIn_btn}
                labelStyle={styles.labelBtn}
            />

            <PrimaryButton
                label={t('signUp')}
                onClick={goSignUp}
                className={styl.signUn_btn}
                labelStyle={{ ...styles.labelBtn, color: '#0d8f5b' }}
            />
        </div>
    )
}

const styles = createStyleSheet({
    labelBtn: {
        fontSize: 16,
        fontWeight: '500',
    },
})
