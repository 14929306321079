import { usePlacesWidget } from 'react-google-autocomplete'

import './styles.css'
import { Icon } from '@/core/components'
import { useSearchCountryStore } from '@/store'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

export const SearchCountry = () => {
    const { t } = useTranslation()
    const isOpen = localStorage.getItem('BAR')
    const { setCountry } = useSearchCountryStore()

    const { ref: antRef }: any = usePlacesWidget({
        apiKey,
        onPlaceSelected: place => {
            parseLocation(place)
        },
        language: 'en',
        inputAutocompleteValue: 'country',
        options: {
            types: ['country'],
        },
    })

    const parseLocation = async (place: any) => {
        const place_id = place.place_id
        const name = place.address_components[0]['long_name']
        const shortName = place.address_components[0]['short_name']

        setCountry({
            placeId: place_id,
            name,
            lng: place?.geometry.location.lng(),
            lat: place?.geometry.location.lat(),
            shortCode: shortName,
        })
    }

    useEffect(() => {
        if (isOpen) {
            antRef.current.blur()
        }
    }, [isOpen, antRef])
    return (
        <div
            className={'container'}
            style={{
                backgroundColor: '#F7F7F7',
                borderRadius: 90,
            }}>
            <Icon
                name='search-normal'
                size={20}
                color='#97999A'
                style={{ position: 'absolute', left: 12 }}
            />

            <input
                className={'search_input'}
                ref={antRef}
                placeholder={t(`searchCountries`)}
                data-testid='search-input'
            />
        </div>
    )
}
