import moment from 'moment'
import { INotification } from '../../typing'
import styles from './styles.module.css'
import { CountriesFor } from '../contries-for'

interface IProps {
    item: INotification
    isFull: boolean
    isLast: boolean
    isRead?: boolean
}
export const NotificationItem = ({ item, isFull, isLast, isRead }: IProps) => {
    return (
        <div
            className={`${styles.container} ${isLast || isFull ? styles.last_notification : styles.notification_border}`}>
            <div>
                <div className={styles.header}>
                    <p className={styles.title}>{item.title}</p>

                    {isRead ? null : (
                        <div
                            className={`${styles.mark_unread}${isFull ? ` ${styles.mark_unread_full}` : ''}`}
                        />
                    )}
                </div>
                <CountriesFor listCode={item?.countries} />
            </div>

            <p
                className={`${styles.content} ${isFull ? styles.full : styles.short}${isFull && !isRead ? ` ${styles.limit_content}` : ''}`}
                dangerouslySetInnerHTML={{ __html: item.content }}
            />
            <p className={styles.date}>
                {moment(item.createdAt).format('DD.MM.YY')}
            </p>
        </div>
    )
}
