import { Icon } from '@/core/components'
import { INotification } from '../../typing'
import { NotificationItem } from '../notification-item'
import { createStyleSheet } from '@/core/helpers'
import {
    colorRiskSubCategory,
    iconSategoryConfig,
    iconSizeRiskSubCategory,
} from '../../configs'
import styl from './styles.module.css'
import { CountriesFor } from '../contries-for'
import { useResizeWindow } from '@/core/hooks'

interface IProps {
    items: INotification[]
    isOne: boolean
    handleScroll?: (e: any) => void
}
export const NotificationsFullList = ({
    items,
    isOne,
    handleScroll,
}: IProps) => {
    const { width: windowWidth } = useResizeWindow()
    const renderItem = (item: INotification, index: number) => {
        if (isOne) {
            return (
                <div className={styl.one_notification}>
                    <CountriesFor listCode={item?.countries} />
                    <p
                        className={styl.content}
                        dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                </div>
            )
        } else
            return (
                <>
                    <div
                        className={`${styl.notification_item} ${item.isRead ? `${styl.read}` : `${styl.unread}`}`}
                        key={index}>
                        <Icon
                            style={{
                                ...styles.iconContainer,
                                height: windowWidth < 768 ? 30 : 42,
                                width: windowWidth < 768 ? 33 : 45,
                                background: colorRiskSubCategory[item.type],
                            }}
                            name={iconSategoryConfig[item.type]}
                            size={
                                windowWidth < 768
                                    ? 14
                                    : iconSizeRiskSubCategory[item.type]
                            }
                            color='#FFFFFF'
                        />

                        <NotificationItem
                            item={item}
                            key={item?.id}
                            isFull={true}
                            isLast={index + 1 === items.length}
                            isRead={item.isRead}
                        />
                    </div>
                </>
            )
    }

    return (
        <div
            onScroll={handleScroll}
            className={styl.notifications_list}
            id='list_notification'>
            {items.map((it, index) => renderItem(it, index))}
        </div>
    )
}

const styles = createStyleSheet({
    iconContainer: {
        borderRadius: 110,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})
