import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    onClick: () => void
    isLoading: boolean
}

export const ReadAllBtnAtom = ({ onClick, isLoading }: IProps) => {
    const { t } = useTranslation()

    if (isLoading) return null

    return (
        <p className={styles.container} onClick={onClick}>
            {t('markAllAsRead')}
        </p>
    )
}
