import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'
import { Icon, PrimaryButton } from '@/core/components'
import { useAccount } from '@/modules/user/hooks'
import { cancelSubsriptionReq } from '../../api'
import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { message } from 'antd'
import { appEvents } from '@/core/events'
import { SubscriptionStatus, SubscriptionType } from '../../typing/enums'
import dayjs from 'dayjs'
import { CancelSubscribeModal } from '../../components'

export const UserSubscribeRedesignWidget = () => {
    const { getAccount, account } = useAccount()
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const closeModal = () => setIsModalOpen(false)

    const openModal = () => setIsModalOpen(true)

    const cancelSubscribe = async () => {
        try {
            await cancelSubsriptionReq()
            await getAccount()
            setIsModalOpen(false)
            message.success(t('successCancelSubscribtion'), 3)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const updateSubsription = () => {
        appEvents.emit('subsribe', {
            isShow: true,
            method: 'buy',
            isUpdate: true,
        })
    }

    const periodSubscribtion: any = {
        [SubscriptionType.Basic]: 1,
        [SubscriptionType.Basic_3m]: 3,
        [SubscriptionType.Premium]: 1,
        [SubscriptionType.Premium_3m]: 3,
    }

    const labelConfigSubscriptios = {
        [SubscriptionType.Basic]: `${t('basic')} / ${t('monthly')}`,
        [SubscriptionType.Basic_3m]: `${t('basic')} / ${t('quaterly')}`,
        [SubscriptionType.Premium]: `${t('extra')} / ${t('monthly')}`,
        [SubscriptionType.Premium_3m]: `${t('extra')} / ${t('quaterly')}`,
    }

    const isBasic =
        SubscriptionType.Basic === account?.subscription?.type ||
        SubscriptionType.Basic_3m === account?.subscription?.type

    const getEndDate = (
        startDay: string,
        subscriptionType: SubscriptionType,
    ): string => {
        const monthsToAdd = periodSubscribtion[subscriptionType]
        const endDate = dayjs(startDay).add(monthsToAdd, 'month')
        return endDate.format('DD.MM.YYYY')
    }

    const getSubscridtionValidDate = () => {
        if (!account?.subscription) {
            return ''
        }
        if (account.subscription?.status === SubscriptionStatus.Trial) {
            const trialEndDate = dayjs(account?.subscription.createdAt).add(
                3,
                'days',
            )
            return trialEndDate.format('DD.MM.YYYY')
        } else {
            return getEndDate(
                account?.subscription?.lastPaymentDate,
                account?.subscription?.type,
            )
        }
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p className={styles.title}>{t('subscription')}</p>

                <p className={styles.date}>{dayjs().format('DD.MM.YYYY')}</p>
            </div>

            <div className={styles.subscribe_container}>
                <Icon
                    name={isBasic ? 'box' : 'crown'}
                    size={32}
                    color='#0D8F5B'
                />
                <p className={styles.subsctibe_title}>
                    {labelConfigSubscriptios[account?.subscription?.type]}
                </p>
                <p className={styles.txt}>
                    Your Extra subscription is active until{' '}
                    <span className={styles.light_txt}>
                        {getSubscridtionValidDate()}
                    </span>{' '}
                    The next payment is scheduled automatically.
                </p>
            </div>

            <div className={styles.actions_container}>
                <PrimaryButton
                    className={styles.primary_btn}
                    onClick={updateSubsription}
                    label={t('updateSubscriptionBtn')}
                    labelStyle={{ fontSize: 12, fontWeight: '500' }}
                />

                <PrimaryButton
                    className={styles.cancel_btn}
                    onClick={openModal}
                    label={t('cancelSubscribtionBtn')}
                    labelStyle={{ fontSize: 12, fontWeight: '500' }}
                />
            </div>
            <CancelSubscribeModal
                close={closeModal}
                isOpen={isModalOpen}
                confirm={cancelSubscribe}
            />
        </div>
    )
}
