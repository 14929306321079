import { Checkbox, CheckboxProps } from 'antd'
import './styles.css'

interface IProps extends CheckboxProps {
    label: string
}
export const Check = ({ label, ...props }: IProps) => {
    return (
        <Checkbox className='custom-checkbox' {...props}>
            {label}
        </Checkbox>
    )
}
