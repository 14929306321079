import { CSSProperties, InputHTMLAttributes, useMemo, useState } from 'react'
import styles from './styles.module.css'
import { Icon } from '../../icons'

interface FormControllProps extends InputHTMLAttributes<HTMLInputElement> {
    value: string
    onChangeVal: (val: string) => void
    label?: string
    error?: string
    styleContainer?: CSSProperties
    inputStyle?: CSSProperties
    isErrorFocused?: boolean
    containerClassName?: string
}

export const FormControlPassword = ({
    value,
    onChangeVal,
    error,
    label,
    styleContainer,
    inputStyle,
    isErrorFocused,
    containerClassName,
    ...props
}: FormControllProps) => {
    const [focused, setFocused] = useState(false)
    const [show, setShow] = useState(false)
    const handleFocus = () => {
        setFocused(true)
    }
    const handleBlur = () => {
        setFocused(false)
    }

    const getClasseLabel = () => {
        let classNames = styles.label
        switch (true) {
            case focused && !value:
                classNames += ` ${styles.focused} ${styles.label_up}`
                break
            case focused && !!value:
                classNames += ` ${styles.focused} ${styles.label_up}`
                break
            case !focused && !!value:
                classNames += ` ${styles.label_up}`
                break
            case !focused && !value:
                classNames += ` ${styles.label_down}`
        }
        return classNames
    }

    const labelField = useMemo(() => {
        if (!label) {
            return null
        }
        return <p className={getClasseLabel()}>{label}</p>
    }, [label, focused])

    const getClassNames = () => {
        let classNames = styles.input_layout
        if (containerClassName) classNames += ` ${styles[containerClassName]}`

        switch (true) {
            case (focused && !!error) || isErrorFocused:
                classNames += ` ${styles.error_focused}`
                break
            case !!error && !focused:
                classNames += ` ${styles.error_field}`
                break
            case focused:
                classNames += ` ${styles.focused}`
                break

            default:
                break
        }
        return classNames
    }

    const eyeIcon = useMemo(() => {
        return (
            <div className={styles.eye}>
                <Icon
                    onClick={() => setShow(!show)}
                    name={show ? 'eye' : 'eye-slash'}
                    size={20}
                    color='#868992'
                />
            </div>
        )
    }, [show])

    return (
        <div style={styleContainer} className={getClassNames()}>
            <>{labelField}</>
            <input
                type={!show ? 'password' : 'text'}
                style={inputStyle}
                value={value}
                onChange={e => onChangeVal(e.target.value)}
                className={styles.text_field}
                onBlur={handleBlur}
                onFocus={handleFocus}
                {...props}
            />
            <>{eyeIcon}</>
            {error ? <p className={styles.error}>{error}</p> : null}
        </div>
    )
}
