import { Icon } from '@/core/components'
import { AutoComplete } from 'antd'
import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { ICountryConfig } from './typing/interfaces'
import { countriesConfig } from './countries.config'
import './styles.css'
import { IShortLocation } from '@/modules/auth/typing'
interface IProps {
    onSelectCountry: (val: IShortLocation) => void
    mb?: number
    label?: string
    error?: string
    data?: any
    disabled?: boolean
    valueHidden?: boolean
    chooseCountries?: string[]
    id?: string
    setFocus?: (val: boolean) => void
    dropdownClassName?: string
    containerClassName?: string
    prefixIcon?: {
        name: string
        color?: string
    }
}

export const SelectCountry = ({
    onSelectCountry,
    label,
    error,
    disabled,
    data,
    valueHidden,
    chooseCountries,
    id,
    setFocus,
    dropdownClassName,
    containerClassName,
    prefixIcon,
}: IProps) => {
    const [focused, setFocused] = useState(false)
    const storeCountries = countriesConfig
    const [options, setOptions] = useState<ICountryConfig[]>(storeCountries)
    const [value, setValue] = useState<any>()
    const [openDrop, serOpenDrop] = useState(false)
    const inputRef = useRef(null)

    const handleSearch = (value: string) => {
        const tampl = options.filter(it =>
            it?.name.toLowerCase().includes(value?.toLowerCase()),
        )
        setOptions(_.isEmpty(tampl) ? storeCountries : tampl)

        setValue(value)
    }

    const onSelect = (value: ICountryConfig) => {
        onSelectCountry({
            countryCode: value?.alpha2,
            locationName: value?.name,
        })

        setValue(value)
    }

    const transformList = options.map(it => ({
        value: it.name,
        disabled: chooseCountries?.includes(it.alpha2),
        ...it,
    }))

    useEffect(() => {
        if (valueHidden) {
            setOptions([])
            serOpenDrop(false)
            setValue('')
            setFocused(false)

            inputRef?.current?.blur()
        }
        return
    }, [valueHidden, disabled, chooseCountries])

    useEffect(() => {
        if (data) {
            handleSearch(data?.locationName)
        }
    }, [data, value])

    useEffect(() => {
        if (!value) {
            setOptions([])
            onSelectCountry(null)
        }
    }, [value])

    const getClasseLabel = () => {
        let classNames = 'label'
        switch (true) {
            case focused && !value:
                classNames += ` ${'focused'} ${'label_up'}`
                break
            case focused && !!value:
                classNames += ` ${'focused'} ${'label_up'}`
                break
            case !focused && !!value:
                classNames += ` ${'label_up'}`
                break

            case !focused && !value:
                classNames += ` ${'label_down'}`
                break
        }
        if (prefixIcon) classNames += ` with_icon`
        return classNames
    }

    const labelField = () => {
        if (!label) {
            return null
        }
        return <p className={getClasseLabel()}>{label}</p>
    }

    const getClassNames = () => {
        let classNames = 'search_layout'

        switch (true) {
            case !!error && !focused:
                classNames += ` error_field`
                break
            case focused:
                classNames += ` ${'focused'}`
                break

            default:
                break
        }

        if (containerClassName) classNames += ` ${containerClassName}`
        if (disabled) classNames += ` selector_disabled`
        return classNames
    }

    const handleBlur = () => {
        setFocused(false)
    }
    const handleFocus = () => {
        setOptions(storeCountries)
        setFocused(true)
        if (setFocus) setFocus(true)
    }

    return (
        <div
            id={`autocomplete_country_container_${id}`}
            className={getClassNames()}>
            {prefixIcon && (
                <div
                    onClick={() => {
                        serOpenDrop(true)
                        inputRef.current?.focus()
                    }}
                    className='icon_container'>
                    <Icon
                        name={prefixIcon.name}
                        size={16}
                        color={_.defaultTo(prefixIcon.color, '#0D8F5B')}
                    />
                </div>
            )}

            {labelField()}

            <AutoComplete
                defaultOpen={false}
                ref={inputRef}
                className={'autocomplete_country'}
                id={id}
                onFocus={handleFocus}
                style={{
                    width: '100%',
                    color: '#0F0E0E',
                }}
                disabled={disabled}
                options={transformList}
                onChange={val => {
                    setValue(val)
                }}
                onBlur={handleBlur}
                allowClear
                value={_.defaultTo(value?.value, value)}
                onSelect={(_, opt) => onSelect(opt)}
                onSearch={handleSearch}
                popupClassName={dropdownClassName}
                onDropdownVisibleChange={serOpenDrop}
                open={openDrop}></AutoComplete>
            {error ? <p className='error'>{error}</p> : null}
        </div>
    )
}
