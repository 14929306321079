import { Icon, PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { PaidElements } from '../../typing/enums'
import { useResizeWindow } from '@/core/hooks'
import { useTranslation } from 'react-i18next'

const positionConfigFull = {
    [PaidElements.EntryReq]: { top: '290px', left: '270px', display: 'flex' },
    [PaidElements.Notification]: {
        top: '80px',
        right: '16px',
        display: 'flex',
    },
    [PaidElements.Maskot]: {
        bottom: '125px',
        right: '120px',
        display: 'flex',
    },
}

const positionConfigTablet = {
    [PaidElements.EntryReq]: {
        top: '290px',
        left: '270px',
        display: 'flex',
        width: 'fit-content',
    },
    [PaidElements.Notification]: {
        top: '80px',
        right: '16px',
        display: 'flex',
        width: 'fit-content',
    },
    [PaidElements.Maskot]: {
        bottom: '125px',
        right: '120px',
        display: 'flex',
        width: 'fit-content',
    },
}

const positionConfigMob = {
    [PaidElements.EntryReq]: {
        bottom: '16px',
        left: '0px',
        right: '0px',
        display: 'flex',
    },
    [PaidElements.Notification]: {
        top: '70px',
        left: '4%',
        display: 'flex',
        width: '92%',
        zIndex: 9999,
    },
    [PaidElements.Maskot]: {
        bottom: '100px',
        right: '18px',
        display: 'flex',
        width: '92%',
    },
}

interface IProps {
    element: PaidElements
    txtContent: string
    goTariffs: () => void
    hideElement: () => void
    hideClose?: boolean
}
export const PopUpSubscribtion = ({
    element,
    txtContent,
    goTariffs,
    hideElement,
    hideClose = false,
}: IProps) => {
    const { t } = useTranslation()
    const { width: windowWidth } = useResizeWindow()

    const positionStyle = (width: number) => {
        switch (true) {
            case width > 1023:
                return positionConfigFull[element]
            case width < 768:
                return positionConfigMob[element]
            case width >= 768 && width < 1024:
                return positionConfigTablet[element]

            default:
                return positionConfigFull[element]
        }
    }
    return (
        <div
            className={styles.container}
            style={{
                ...positionStyle(windowWidth),
            }}>
            <div className={styles.row}>
                <Icon name='warning-2' size={14} color='#FFAE00' />
                <p className={styles.txt}>{txtContent}</p>
            </div>

            <div className={styles.row}>
                <PrimaryButton
                    onClick={goTariffs}
                    className={styles.buy_btn}
                    labelStyle={{
                        fontSize: 12,
                        fontWeight: '500',
                        lineHeight: '18px',
                    }}
                    label={t('buyNow')}
                />
                {hideClose ? null : (
                    <Icon
                        name='x'
                        size={14}
                        color='rgba(0, 0, 0, 0.85)'
                        onClick={hideElement}
                    />
                )}
            </div>
        </div>
    )
}
