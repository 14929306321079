import styles from './styles.module.css'
import { GroupButtonsAtom } from './atoms'
import { Icon } from '../icons'
import { useTranslation } from 'react-i18next'
import { stepIconsConfig } from './step.config'
import { CategoryRisks } from '@/core/typing'

export const IntroStep = ({
    index,
    step,
    backProps,
    primaryProps,
    skipProps,
    isLastStep,
}: any) => {
    const { t } = useTranslation()

    const handleNext = (e: any) => {
        primaryProps.onClick(e)
    }
    const lengthSteps = 11
    return (
        <div
            className={styles.container}
            style={{
                width: step.key === CategoryRisks.Requirements ? 500 : 411,
            }}>
            <div className={styles.header}>
                <div className={styles.row_header}>
                    <Icon
                        name={stepIconsConfig[step.key]}
                        size={16}
                        color='#FFFFFF'
                        className={styles.icon_category}
                    />
                    <p className={styles.title}>{t(`${step.title}`)}:</p>
                </div>
                <button onClick={skipProps.onClick} className={styles.cross}>
                    {t('skip')}
                </button>
            </div>

            <div className={styles.content}>
                <p className={styles.txt_contnent}>{t(`${step.content}`)}</p>

                <div className={styles.actions}>
                    <p>
                        {index + 1}
                        <span className={styles.static_pagination}>
                            /{lengthSteps}
                        </span>
                    </p>
                    <div className={styles.btn_group}>
                        <GroupButtonsAtom
                            labelBack={index === 0 ? t('quit') : t('back')}
                            labelNext={isLastStep ? t('done') : t('next')}
                            isFirst={index === 0}
                            goBack={
                                index === 0
                                    ? skipProps.onClick
                                    : backProps.onClick
                            }
                            next={handleNext}
                            isLast={lengthSteps == index + 1}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
