import { Tariff } from '../../components'
import styles from './styles.module.css'
import fullLogo from '@/assets/images/full_logo.png'
import { Icon } from '@/core/components'
import {
    base,
    basic3Month,
    premium,
    premium3Month,
} from '../../configs/advantes.config'
import { useState } from 'react'
import { useEventsListener } from '@/core/hooks'
import { appEvents } from '@/core/events'
import { saveSubscribeReq } from '../../api'
import { SubscriptionStatus, SubscriptionType } from '../../typing/enums'
import { useAccount } from '@/modules/user/hooks'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

export const Subscribe = () => {
    const { t } = useTranslation()

    const [modal, setModal] = useState<{
        isShow: boolean
        method: 'buy'
        isUpdate?: boolean
    }>({
        isShow: false,
        method: 'buy',
    })

    const { account } = useAccount()

    const close = () =>
        appEvents.emit('subsribe', {
            isShow: false,
            method: null,
        })

    useEventsListener(
        'subsribe',
        (data: any) => {
            setModal({
                isShow: data?.isShow,
                method: data?.method,
                isUpdate: data?.isUpdate,
            })
        },
        [],
    )

    const chooseSubscribe = async (val: SubscriptionType) => {
        try {
            const { data } = await saveSubscribeReq({ subscriptionType: val })

            window.open(data.url, '_self')

            close()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const handleCliskTariff = async (val: SubscriptionType) => {
        if (modal?.method === 'buy') {
            await chooseSubscribe(val)
        }
    }

    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    const labelBtn = t('buy')

    if (!modal.isShow) return null
    return (
        <div className={styles.container}>
            <div className={styles.logo_container}>
                <img
                    src={fullLogo}
                    style={{
                        height: 38,
                    }}
                />
            </div>

            <Icon
                name='x'
                size={24}
                color='#000000'
                onClick={close}
                className={styles.close}
            />
            <div>
                <p className={styles.title}>{t('titleSubscribes')}</p>
                <div className={styles.subscribes}>
                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Basic_3m && modal?.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        advantes={basic3Month}
                        label={t('basic3')}
                        type={t('popular')}
                        isPrimary={false}
                        price='7,99'
                        onClick={() =>
                            handleCliskTariff(SubscriptionType.Basic_3m)
                        }
                    />

                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Basic && modal?.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        advantes={base}
                        label={t('basic')}
                        isPrimary={false}
                        price='8,99'
                        onClick={() =>
                            handleCliskTariff(SubscriptionType.Basic)
                        }
                    />

                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Premium_3m && modal?.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        includeBasic
                        advantes={premium3Month}
                        isHaveHat={true}
                        label={t('extra3')}
                        type={t('popular')}
                        isPrimary={true}
                        price='9,99'
                        onClick={() =>
                            handleCliskTariff(SubscriptionType.Premium_3m)
                        }
                    />

                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Premium && modal.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        includeBasic
                        advantes={premium}
                        label={t('extra')}
                        isPrimary={true}
                        price='10,99'
                        onClick={() =>
                            handleCliskTariff(SubscriptionType.Premium)
                        }
                    />
                </div>
            </div>
        </div>
    )
}
