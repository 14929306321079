import { PropsWithChildren } from 'react'
import styles from './styles.module.css'

interface IProps {
    title: string
    className?: string
    titleClassName?: string
    classNameChildren?: string
}
export const LayoutSettings = ({
    children,
    title,
    className,
    titleClassName,
    classNameChildren,
}: PropsWithChildren<IProps>) => {
    return (
        <div
            className={`${styles.container}${className ? ` ${className}` : ''}`}>
            <p
                className={`${styles.title}${titleClassName ? ` ${titleClassName}` : ''}`}>
                {title}
            </p>
            <div className={classNameChildren}>{children}</div>
        </div>
    )
}
