import logoStar from '@/assets/images/start_logo.svg'

import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { Icon } from '@/core/components'

interface IProps {
    onClick: () => void
}
export const FullSubscribe = ({ onClick }: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container} onClick={onClick}>
            <img src={logoStar} className={styles.img} />
            <p className={styles.label}>{t('fullSubsctiption')}</p>
            <p className={styles.sublabel}>
                {t('buyNow')}
                <div className={styles.icon_arrow}>
                    <Icon name='arrow-right' size={17} color='#FFFFFF' />
                </div>
            </p>
        </div>
    )
}
