import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

export const MostPopularAtom = () => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <Icon name='star' size={16} color='#262933' />
            <p className={styles.label}>{t('popular')}</p>
        </div>
    )
}
