import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { SubscribesHistoryAtom } from './atoms/subscribes-history'
import * as Sentry from '@sentry/react'
import { getSubscribeHistoryReq } from '../../api'

export const UserSubscribeWidget = () => {
    const [history, setHistory] = useState<any>([])

    const getHistorySubscribe = async () => {
        try {
            const { data } = await getSubscribeHistoryReq()
            setHistory(
                data.map((it: any) => ({
                    date: it.createdAt,
                    type: it.type,
                })),
            )
        } catch (error) {
            Sentry.captureException(error)
        }
    }
    useEffect(() => {
        getHistorySubscribe()
    }, [])

    return (
        <div className={styles.container} id='account-subscription-section'>
            <SubscribesHistoryAtom history={history} />
        </div>
    )
}
