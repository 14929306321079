import { Icon } from '@/core/components'
import { createStyleSheet } from '@/core/helpers'
import { useTranslation } from 'react-i18next'
interface IProps {
    isOpen: boolean
    onClick: () => void
}
export const AboutUsBtn = ({ isOpen, onClick }: IProps) => {
    const { t } = useTranslation()
    return (
        <button onClick={onClick} style={styles.container}>
            {!isOpen ? (
                <Icon
                    name='inform'
                    size={20}
                    color={!isOpen ? '#262933' : '#717375'}
                    style={{
                        ...styles.iconInfo,
                        background: !isOpen && '#FFFFFF',
                    }}
                />
            ) : (
                <>
                    {t('aboutUs')}
                    <Icon name='arrow-2' size={24} color='#717375' />
                </>
            )}
        </button>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        alignItems: 'center',
        color: '#717375',
        gap: 8,
        fontSize: 16,
        fontWeight: '500',
        cursor: 'pointer',
        textWrap: 'nowrap',
        marginLeft: 3,
    },
    iconInfo: {
        height: 32,
        width: 32,
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
    },
})
