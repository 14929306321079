import { Icon } from '@/core/components'
import styles from './styles.module.css'

interface IProps {
    category: string
    title: string
    description: string
    date: string
}
export const AlertItemAtom = ({
    category,
    title,
    description,
    date,
}: IProps) => {
    return (
        <div className={styles.alert_container}>
            <div className={styles.alert_label_row}>
                <Icon name='warning-3' size={24} color='#F6190B' />
                <p className={styles.label_alert}>{category}</p>
            </div>
            <p className={styles.secondary_content}>{description}</p>
            <p className={styles.alert_meteo}>{title}</p>

            <p className={styles.date_alert}>{date}</p>
        </div>
    )
}
