import { useResizeWindow } from '@/core/hooks'
import styles from './styles.module.css'
import { FaqQuestion } from '../faq-question'
import { transaltesFAQSConfig } from '../../config'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/store'

export const FrequentlyAsked = () => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { width: windowWidth } = useResizeWindow()

    const title = windowWidth < 768 ? 'FAQs' : t('titleFAQ')
    return (
        <div className={styles.container}>
            <p className={styles.title}>{title}</p>

            <div className={styles.list}>
                {transaltesFAQSConfig[lang].map(it => (
                    <FaqQuestion
                        idCard={it.id}
                        question={it.question}
                        answer={it.answer}
                    />
                ))}
            </div>
        </div>
    )
}
