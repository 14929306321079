import { useEffect, useMemo, useState } from 'react'
import { MenuItem } from './atoms'
import styles from './styles.module.css'
import { IMenu } from './typing'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/store/language.store'
import { CategoryRisks, Languages } from '@/core/typing'
import { Icon } from '../icons'
import { useResizeWindow } from '@/core/hooks'
import { useAccountStore } from '@/store'
import { appEvents } from '@/core/events'

interface IProps {
    indexMenu: number
    item: IMenu
    onClickMenu: (val: { category: string; subcategory?: string }) => void
    activeCategory: { category: string; subcategory?: string }
    id?: string
    close: () => void
}
export const MenuRedesign = ({
    item,
    onClickMenu,
    activeCategory,
    indexMenu,
    id,
    close,
}: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const isOpenBar: boolean = JSON.parse(localStorage.getItem('BAR'))
    const { account } = useAccountStore()

    const [prevCategory, setPrevCategory] = useState<CategoryRisks>()

    const [open, setOpen] = useState(false)

    const { width: widthWindow } = useResizeWindow()
    const isActiveCategory = activeCategory?.category === item.key

    const manageOpenMenu = () => {
        if (!isOpenBar) {
            setOpen(false)
        } else if (isActiveCategory && isOpenBar) {
            setOpen(true)
        } else if (
            activeCategory?.category === CategoryRisks.Climate &&
            prevCategory !== activeCategory?.category
        ) {
            setOpen(false)
        }
    }

    const manageCloseMenu = () => {
        if (
            (prevCategory !== activeCategory?.category &&
                !activeCategory?.subcategory) ||
            !account?.subscription
        ) {
            setOpen(false)
        } else if (
            activeCategory?.category === CategoryRisks.Climate &&
            prevCategory !== activeCategory?.category &&
            isActiveCategory
        ) {
            setOpen(true)
        }
    }

    useEffect(() => {
        manageOpenMenu()
        manageCloseMenu()
    }, [activeCategory, isOpenBar, prevCategory, account?.subscription])

    const toggleOpen = () => {
        if (!account) {
            close()
            appEvents.emit('previewTariffs', {
                isShow: true,
            })
        } else if (account && !account?.subscription) {
            close()
            appEvents.emit('subsribe', {
                isShow: true,
                method: 'buy',
            })
        } else {
            onClickMenu({ category: item.key })
            setPrevCategory(item.key as CategoryRisks)
            setOpen(prev => !prev)
        }
    }

    const onClickItem = (val: string) => {
        if (!account) {
            appEvents.emit('previewTariffs', {
                isShow: true,
            })
            close()
        } else if (account && !account?.subscription) {
            appEvents.emit('subsribe', {
                isShow: true,
                method: 'buy',
            })
            close()
        } else {
            onClickMenu({ category: item.key, subcategory: val })
        }
    }

    const languageHeightMenuWeb: any = [
        {
            [Languages.EN]: 105,
            [Languages.FR]: 105,
            [Languages.DE]: 105,
            [Languages.ES]: 105,
        },
        {
            [Languages.EN]: 130,
            [Languages.FR]: 145,
            [Languages.DE]: 140,
            [Languages.ES]: 130,
        },
        {
            [Languages.EN]: 65,
            [Languages.FR]: 65,
            [Languages.DE]: 65,
            [Languages.ES]: 65,
        },
        {
            [Languages.EN]: 70,
            [Languages.FR]: 70,
            [Languages.DE]: 65,
            [Languages.ES]: 65,
        },
    ]

    const languageHeightMenuMob: any = [
        {
            [Languages.EN]: 105,
            [Languages.FR]: 105,
            [Languages.DE]: 105,
            [Languages.ES]: 105,
        },
        {
            [Languages.EN]: 105,
            [Languages.FR]: 135,
            [Languages.DE]: 140,
            [Languages.ES]: 130,
        },
        {
            [Languages.EN]: 70,
            [Languages.FR]: 65,
            [Languages.DE]: 65,
            [Languages.ES]: 70,
        },
        {
            [Languages.EN]: 70,
            [Languages.FR]: 70,
            [Languages.DE]: 65,
            [Languages.ES]: 65,
        },
    ]

    const munuHeightConfig =
        widthWindow < 768 ? languageHeightMenuMob : languageHeightMenuWeb
    const heightList = (ind: number) => {
        if (!open) {
            return 0
        } else if (open && ind == 0) {
            return munuHeightConfig[0][lang]
        } else if (open && ind == 1) {
            return munuHeightConfig[1][lang]
        } else if (open && ind == 2) {
            return munuHeightConfig[2][lang]
        } else if (open && ind == 3) {
            return munuHeightConfig[3][lang]
        }
        return 110
    }

    const heightSideLine = (ind: number) => {
        if (!open) {
            return 0
        } else if (open && ind == 0) {
            return 95
        } else if (open && ind == 1) {
            return 115
        } else if (open && ind == 2) {
            return 70
        } else if (open && ind == 3) {
            return 60
        }
    }

    const list = useMemo(() => {
        if (!item?.items || (!isOpenBar && !activeCategory?.subcategory))
            return null
        return (
            <div
                style={{ height: heightList(indexMenu) }}
                className={
                    !open
                        ? `${styles.list_items} ${styles.collapsed}`
                        : `${styles.list_items}`
                }>
                <div
                    className={styles.line}
                    style={{ height: heightSideLine(indexMenu) }}
                />
                {item?.items.map((it, index) => (
                    <MenuItem
                        isActive={activeCategory?.subcategory === it.key}
                        key={it.key}
                        index={index}
                        item={it}
                        onClick={() => onClickItem(it.key)}
                    />
                ))}
            </div>
        )
    }, [
        isOpenBar,
        open,
        activeCategory,
        prevCategory,
        indexMenu,
        account?.subscription,
    ])

    const arrowMenu = () => {
        if (!item?.items || !isOpenBar) return null
        return (
            <div
                className={`${styles.arrow} ${
                    isActiveCategory && open ? styles.up : styles.down
                } ${isOpenBar ? styles.show : ''}`}>
                <Icon
                    name='arrow-up'
                    size={20}
                    color={isActiveCategory ? '#0F0E0E' : '#717375'}
                />
            </div>
        )
    }

    return (
        <div className={`${styles.collapsible_list}`}>
            <div
                id={id}
                onClick={toggleOpen}
                className={styles.collapseble_item}>
                <div
                    className={styles.menu_label_container}
                    style={{ display: isOpenBar ? 'flex' : 'block' }}>
                    <Icon
                        onClick={_.noop}
                        name={item.iconName}
                        size={14}
                        color={isActiveCategory ? '#FFFFFF' : '#0F0E0E'}
                        style={{
                            backgroundColor: isActiveCategory
                                ? '#0D8F5B'
                                : '#FFFFFF',
                        }}
                        className={styles.icon_category}
                    />

                    <p
                        style={{
                            color: '#0F0E0E',
                        }}
                        className={
                            !isOpenBar ? styles.collapsed : styles.label_menu
                        }>
                        {t(`${item?.label}`)}
                    </p>
                </div>
                <>{arrowMenu()}</>
            </div>
            <>{list}</>
        </div>
    )
}
