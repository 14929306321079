import {
    FormControll,
    FormControlPassword,
    PrimaryButton,
} from '@/core/components'
import { ISignInForm } from '../../typing'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface IProps {
    values: ISignInForm
    onChange: (key: keyof ISignInForm, val: any) => void
    errors: Partial<ISignInForm>
    submit: () => void
    errorApi?: string
}

export const SignInForm = ({
    values,
    onChange,
    errors,
    submit,
    errorApi,
}: IProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const starSymbol = '\u002A'

    return (
        <div className={styles.form_container}>
            <div className={styles.fields_container}>
                <FormControll
                    type='email'
                    onChangeVal={val => onChange('email', val)}
                    value={values.email}
                    label={`${t('email')} ${starSymbol}`}
                    error={errors.email}
                    isErrorFocused={!!errorApi}
                />

                <div
                    style={{
                        position: 'relative',
                        marginBottom: errorApi ? 12 : 0,
                    }}>
                    <FormControlPassword
                        onChangeVal={val => onChange('password', val)}
                        label={`${t('password')} ${starSymbol}`}
                        error={errors.password}
                        value={values.password}
                        isErrorFocused={!!errorApi}
                    />

                    {errorApi ? (
                        <p className={styles.error}>{errorApi}</p>
                    ) : null}
                </div>
            </div>

            <p
                className={styles.forgotPassword}
                onClick={() => navigate('password')}>
                {t('forgotpassword')}?
            </p>

            <div className={styles.btn_container}>
                <PrimaryButton
                    disabled={!!errorApi}
                    label={t('signIn')}
                    onClick={submit}
                    style={{ width: '100%', height: 48 }}
                    withArrow={true}
                />
            </div>
        </div>
    )
}
