import { SocialButton } from '@/core/components'
import { getDataFromApiError } from '@/core/helpers'
import { useForm, useSession } from '@/core/hooks'
import { Divider, message } from 'antd'
import _ from 'lodash'
import { gregisterUserReq } from '../../api'
import { RegisterForm } from '../../components'
import { useSocialAuth } from '../../hooks'
import { AuthStep, IRegisterForm } from '../../typing'
import { validateRegister } from '../../validators'
import * as Sentry from '@sentry/react'

import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { FacebookSignInWidget } from '../facebook-sign-in'
interface IProps {
    changeStep: (val: AuthStep) => void
}
export const RegisterUserWidget = ({ changeStep }: IProps) => {
    const { t } = useTranslation()
    const { signInByGoogle, getSessionByFacebook } = useSocialAuth()
    const { values, setField, errors, onSubmit } = useForm<IRegisterForm>(
        {},
        validateRegister,
    )
    const { saveSession } = useSession()

    const registerUser = async () => {
        try {
            const { data } = await gregisterUserReq({
                ..._.omit(values, ['confirmPassword']),
                deviceName: 'pc',
            })
            saveSession(data)
        } catch (error: any) {
            const errorData: any = getDataFromApiError(error)
            message.error(errorData?.message || 'Something wrong')
            Sentry.captureException(error)
        }
    }

    const onChangeField = (key: keyof IRegisterForm, val: any) => {
        setField(key, val)
    }

    return (
        <div className={styles.container}>
            <div className={styles.containerHeader}>
                <p className={styles.title}>{t('titleSignUp')}</p>
            </div>

            <RegisterForm
                values={values}
                errors={errors}
                onChange={onChangeField}
                submit={() => onSubmit(registerUser)}
            />

            <p className={styles.login}>
                {t('existAccount')}{' '}
                <span
                    onClick={() => changeStep(AuthStep.Login)}
                    className={styles.labelLogin}>
                    {t('signIn')}
                </span>
            </p>

            <div className={styles.social}>
                <div className={styles.separator}>
                    <Divider
                        style={{
                            borderColor: '#EBF0FE',
                            width: '100%',
                            marginTop: 24,
                            marginBottom: 24,
                        }}>
                        <p
                            style={{
                                color: '#262933',
                                fontSize: 12,
                                lineHeight: '16px',
                                fontWeight: 400,
                            }}>
                            Or
                        </p>
                    </Divider>
                </div>

                <div className={styles.rowBtn}>
                    <SocialButton
                        className={styles.social_btn}
                        onClick={signInByGoogle}
                        label={t('signUpGoogle')}
                        socialName='google'
                    />

                    <FacebookSignInWidget
                        classname={styles.social_btn}
                        getSession={getSessionByFacebook}
                        keyLabel='signUnFacebook'
                    />
                </div>
            </div>
        </div>
    )
}
