import { Select, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import './style.css'
import { useState } from 'react'
import _ from 'lodash'

interface IProps extends SelectProps {
    onChangeSelect: (val: string | DefaultOptionType) => void
    items: DefaultOptionType[]
    label: string
    disabled?: boolean
    id?: string
    setFocus?: (val: boolean) => void
    dropdownClassName?: string
}

export const SelectControl = ({
    onChangeSelect,
    items,
    label,
    disabled,
    setFocus,
    ...props
}: IProps) => {
    const [focused, setFocused] = useState(false)

    const handleFocus = () => {
        setFocused(true)
        setFocus(true)
    }
    const handleBlur = () => {
        setFocused(false)
    }

    const getClasseLabel = () => {
        let classNames = 'label'
        switch (true) {
            case focused && !props.value:
                classNames += ` focused-select label_up`
                break
            case focused && !!props.value:
                classNames += ` focused-select label_up`
                break
            case !focused && !!props.value:
                classNames += ` label_up`
                break
            case !focused && !props.value:
                classNames += ` label_down`
        }
        return classNames
    }

    const getClasseSelect = () => {
        let classNames = 'base-select'
        switch (true) {
            case focused && !props.value:
                classNames += ` focused-select`
                break
            case focused && !!props.value:
                classNames += ` focused-select`
                break
        }
        return classNames
    }

    const labelSelect = () => {
        return <p className={getClasseLabel()}>{label}</p>
    }

    return (
        <div
            className={`select_container${disabled ? ' disabled_select' : ''}`}>
            <>{labelSelect()}</>

            <Select
                popupClassName={props.dropdownClassName}
                disabled={disabled}
                allowClear
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={getClasseSelect()}
                onChange={(_, opt) => onChangeSelect(opt)}
                options={items}
                {..._.omit(props, 'dropdownClassName')}
            />
        </div>
    )
}
