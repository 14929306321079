import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { appEvents } from '@/core/events'

interface IWeatherLocation {
    city: string
    country: string
    region: string
}
interface IProps {
    weatherLocation: IWeatherLocation
    clearSearchCity: () => void
}
export const MobileHeaderWeather = ({
    weatherLocation,
    clearSearchCity,
}: IProps) => {
    const handleGoBack = () => {
        clearSearchCity()
        appEvents.emit('weatherDetailed', { isShow: false })
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p className={styles.label}>
                    {weatherLocation.city}{' '}
                    <span>{`(${weatherLocation.region} ${weatherLocation.country})`}</span>
                    <p className={styles.tag_label}>Weather</p>
                </p>
                <Icon
                    onClick={handleGoBack}
                    name='x'
                    size={22}
                    color='rgba(94, 98, 109, 1)'
                />
            </div>
        </div>
    )
}
