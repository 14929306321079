import { Icon, PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import {
    subscribeRedesignLabel,
    themeTariff,
} from '@/modules/subscribe/configs/advantes.config'
import { useTranslation } from 'react-i18next'

interface IProps {
    buyNow: () => void
    buyTrial: () => void
    typeSubscription: SubscriptionType
}
export const ActionsTariffAtom = ({
    buyNow,
    buyTrial,
    typeSubscription,
}: IProps) => {
    const theme = themeTariff[typeSubscription]
    const labelBtn = subscribeRedesignLabel[typeSubscription]
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <PrimaryButton
                style={{ backgroundColor: theme.primaryBtn }}
                className={styles.primary_btn}
                labelStyle={{
                    fontSize: 14,
                    fontWeight: '500',
                    color: theme.primaryBtnLabelColor,
                }}
                onClick={buyNow}
                label={t(labelBtn)}
                rightIcon={
                    <Icon
                        name='arrow-right'
                        size={16}
                        color={theme.primaryBtnLabelColor}
                    />
                }
            />

            <PrimaryButton
                style={{ backgroundColor: theme.secondaryBtn }}
                className={styles.outline_btn}
                labelStyle={{
                    fontSize: 14,
                    fontWeight: '500',
                    color: theme.secondaryBtnLabelColor,
                }}
                onClick={buyTrial}
                label={t('grandTrial')}
            />
        </div>
    )
}
