import {
    GoogleMap,
    SeaLevelMapWidget,
    WeatherMapRedesign,
} from '@/modules/map/widgets'
import { useEffect, useMemo, useState } from 'react'
import { ArticleWidget } from '@/modules/article/widgets'
import { LegendRisk, SliderControlSea } from '@/modules/map/components'
import {
    useEventsListener,
    useResizeWindow,
    useRisksCategory,
    useSocketListener,
} from '@/core/hooks'
import { useGetCountries } from '@/modules/map/hooks/use-get-countries.hook'
import _ from 'lodash'
import {
    Subscribe,
    // TariffPlansPage,
    TariffPlansRedesignPage,
} from '@/modules/subscribe/pages'
import { SubcategoryRisks } from '@/core/typing'
import { useSearchCityStore, useSearchCountryStore } from '@/store'
import { useAccount } from '@/modules/user/hooks'
import { LayoutPageAtom } from './atoms'
import styles from './styles.module.css'
import { ChatBotWidget } from '@/modules/chat-bot/widgets'
import { GuidoBot } from '@/modules/chat-bot/components'
import {
    FullNotiicationsWidget,
    SettingsNotiificationsModalWidget,
} from '@/modules/notifications/widgets'
import { RequrementsWidget } from '@/modules/entry-requirements/widgets/requirements'
import { AccountPage } from '@/modules/user/pages'
import { IntroStepMobileWidget, IntroStepsWidgets } from '@/core/widgets'
import { GreetingTravy } from '@/core/components'
import { appEvents } from '@/core/events'
import { AfterPayment } from '@/core/components/modals/after-payment'
import { clearUrl } from '@/core/helpers'
import { AboutUsWidget, FaqsListWidget, FAQsWidget } from '@/modules/FAQ'
import { isNewCategory } from '@/modules/risks/helpers'
import { NaturalMap } from '@/modules/map/widgets/natural-map'

export const MainPage = () => {
    const defValue = localStorage.getItem('BAR')
    const { width: widthWindow } = useResizeWindow()

    const legend = localStorage.getItem('LEGEND')
    const [isOpen, setIsOpen] = useState(JSON.parse(defValue || 'true'))
    const [openAtricle, setOpenArticle] = useState(false)
    const { active } = useRisksCategory()
    const { setCountry } = useSearchCountryStore()
    const { countriesPlaceIds, countryList } = useGetCountries()
    const { account, getAccount } = useAccount()
    const { clearCity } = useSearchCityStore()

    const [countryCode, setCountryCode] = useState<{
        code: string
        longName: string
    }>(null)
    const [borderMap, setBorderMap] = useState<boolean>()

    useEffect(() => {
        clearCity()
    }, [active])

    useEventsListener('intro', data => setBorderMap(data.isShow), [])

    const location = window.location?.href
    useSocketListener('subscription/new', () => {
        clearUrl(location)
        appEvents.emit('requestSubscribe', { isShow: false })

        getAccount()
        if (widthWindow < 768) {
            appEvents.emit('intro', { isShow: true })
        } else {
            appEvents.emit('greeting', { isShow: true })
            localStorage.setItem('LEGEND', JSON.stringify(true))
        }
    })

    useSocketListener('subscription/changed', () => {
        clearUrl(location)
        appEvents.emit('requestSubscribe', { isShow: false })

        getAccount()
    })

    useSocketListener('subscription/cancelled', () => {
        getAccount()
    })

    const handleChangeDrawer = (val: boolean) => setOpenArticle(val)

    const handleChangeCode = (val: { code: string; longName: string }) => {
        setCountryCode(val)
        setOpenArticle(!!active?.subcategory || isNewCategory(active?.category))
    }

    useEffect(() => {
        if (_.isNil(defValue)) {
            localStorage.setItem('BAR', JSON.stringify(true))
        }
        if (_.isNil(legend)) {
            localStorage.setItem('LEGEND', JSON.stringify(true))
        }
    }, [defValue, legend])

    useEffect(() => {
        setCountry(null)
    }, [active])

    useEffect(() => {
        setCountryCode(null)
    }, [active?.subcategory])

    const toggleSidebar = (val: boolean) => {
        setIsOpen(val)
        localStorage.setItem('BAR', String(val))
    }

    const legendRisk = useMemo(() => {
        if (active?.subcategory === SubcategoryRisks.Weather) {
            return null
        } else if (active?.subcategory === SubcategoryRisks.SeaLevelRise) {
            return <SliderControlSea />
        }
        return <LegendRisk />
    }, [active, length])

    const map = useMemo(
        () => ({
            google: (
                <GoogleMap
                    countriesList={countriesPlaceIds}
                    setCountryCode={handleChangeCode}
                />
            ),
            weather: <WeatherMapRedesign />,
            seaLevel: <SeaLevelMapWidget />,
            requirements: <NaturalMap />,
        }),
        [active, countriesPlaceIds],
    )

    const chatBot = useMemo(() => {
        if (!account || !account?.subscription) return null
        return <GuidoBot />
    }, [account])

    return (
        <div className={styles.container}>
            <LayoutPageAtom
                borderMap={borderMap}
                isOpenBar={isOpen}
                openSideBar={toggleSidebar}
                legendRisk={legendRisk}
                map={map}
                active={active}
                chatBot={chatBot}
            />
            {borderMap ? (
                <div id='profile' className={styles.account_tutorial} />
            ) : null}
            {countryList?.some(it => it?.countryCode === countryCode?.code) ? (
                <ArticleWidget
                    categoryGroup={active}
                    open={openAtricle}
                    onChangeDrawer={handleChangeDrawer}
                    countryCode={countryCode}
                />
            ) : null}

            <IntroStepsWidgets />
            <ChatBotWidget />
            <FAQsWidget />
            <FullNotiicationsWidget />
            <Subscribe />
            <RequrementsWidget />
            <SettingsNotiificationsModalWidget />
            <AccountPage />
            <GreetingTravy />
            <IntroStepMobileWidget />
            <AfterPayment />
            <FaqsListWidget />
            <TariffPlansRedesignPage />
            {/* <TariffPlansPage /> */}
            <AboutUsWidget />
        </div>
    )
}
