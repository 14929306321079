import { Modal } from 'antd'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useEventsListener } from '@/core/hooks'
import { appEvents } from '@/core/events'
import logo from '@/assets/images/guido_bot.png'
import { DoteLoader } from '../../loaders'

export const AfterPayment = () => {
    const { t } = useTranslation()
    const location = window.location?.href

    const [isOpen, setIsOpen] = useState(false)

    const isSucceeded = (val: string) => val.includes('succeeded')

    const afterPaidFlow = () => {
        if (isSucceeded(location)) {
            appEvents.emit('subsribe', { isShow: false })
            appEvents.emit('requestSubscribe', { isShow: true })
        } else {
            setIsOpen(false)
        }
    }
    useEventsListener('requestSubscribe', data => setIsOpen(data.isShow))

    useEventsListener('greeting', data => {
        if (data.isShow) {
            setIsOpen(false)
        }
    })

    useEffect(() => {
        afterPaidFlow()
    }, [location])

    return (
        <Modal
            styles={{ body: { width: '100%' } }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={isOpen}>
            <div className={styles.content_modal}>
                <img src={logo} style={{ height: 100, width: 100 }} />
                <DoteLoader />
                <p className={styles.title}>{t('modalTitle')}</p>
                <p className={styles.subtitle}>{t('subtitleModal')}</p>
            </div>
        </Modal>
    )
}
