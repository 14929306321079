import { Modal } from 'antd'
import handleX from '@/assets/images/handle-x.svg'
import styles from './styles.module.css'
import { Icon, PrimaryButton } from '@/core/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    isOpen: boolean
    close: () => void
    confirm: () => void
}

export const ModalQuestionsReject = ({ isOpen, close, confirm }: IProps) => {
    const { t } = useTranslation()
    return (
        <Modal
            styles={{ body: { width: '100%' } }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={isOpen}>
            <Icon
                onClick={close}
                name='x'
                size={24}
                color='#CED3E1'
                style={{ position: 'absolute', top: 20, right: 20 }}
            />
            <div className={styles.content_modal}>
                <img src={handleX} style={{ height: 100, width: 100 }} />
                <div>
                    <p className={styles.title}>Are you sure?</p>
                    <p className={styles.sub_text}>{t('consideration')}</p>
                </div>

                <div className={styles.btn_group}>
                    <PrimaryButton
                        label={t('cancel')}
                        onClick={close}
                        className={styles.cancel_btn}
                        labelStyle={{ color: '#262933' }}
                    />

                    <PrimaryButton
                        label={t('quit')}
                        style={{ width: '50%' }}
                        onClick={confirm}
                    />
                </div>
            </div>
        </Modal>
    )
}
