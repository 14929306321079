import { Button } from 'antd'
import styles from './styles.module.css'
import fullLogo from '@/assets/images/full_logo.png'
import { Icon } from '@/core/components'
import { AuthStep } from '../../typing'
import { useTranslation } from 'react-i18next'

interface IProps {
    component: JSX.Element
    goBackName: string
    onGoBack: () => void
}

export const ManagePaswordLayout = ({
    component,
    goBackName,
    onGoBack,
}: IProps) => {
    const { t } = useTranslation()
    const goBackButton = () => {
        return (
            <Button className={styles.go_back} type='text' onClick={onGoBack}>
                {goBackName === AuthStep.NewPassword ? null : (
                    <Icon name='left-arrow' size={20} color='#000000' />
                )}
                <p>
                    {goBackName === AuthStep.NewPassword
                        ? t('cancel')
                        : t('back')}
                </p>
            </Button>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.left_container}>
                <div className={styles.header}>
                    <>{goBackButton()}</>
                    <i
                        onClick={onGoBack}
                        className={`icon-left-arrow ${styles.left_arrow}`}
                    />
                    <img
                        src={fullLogo}
                        className={`${styles.fullLogo} ${styles.headerLogo}`}
                    />
                </div>

                <div className={styles.content}>{component}</div>
            </div>
            <div className={styles.img_illustration}>
                <div className={styles.logo_block}>
                    <img src={fullLogo} className={styles.fullLogo} />
                    <p>
                        <span className={styles.logo_text}>
                            {t('authLogoTextStart')}{' '}
                            <span className={styles.logo_text_accent}>
                                {t('authLogoTextEnd')}
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}
