import { TypeCategoryNotifications } from '../typing'

export const iconSategoryConfig: any = {
    [TypeCategoryNotifications.GeopoliticalConflicts]: 'boom',
    [TypeCategoryNotifications.MedicalRisk]: 'thermometer',
    [TypeCategoryNotifications.InternalSocialTensions]: 'warning',
    [TypeCategoryNotifications.ClimateEnvironmental]: 'waves',
    [TypeCategoryNotifications.PotentialInsecurity]: 'shield',
    [TypeCategoryNotifications.BordersEntry]: 'map-indicator',
    [TypeCategoryNotifications.LGBT]: 'lgbt',
    [TypeCategoryNotifications.TransportUpdates]: 'ticket',
    [TypeCategoryNotifications.FemaleTravel]: 'women',
}

export const colorRiskSubCategory: any = {
    [TypeCategoryNotifications.GeopoliticalConflicts]: '#FFAE00',
    [TypeCategoryNotifications.ClimateEnvironmental]: '#0047EE',
    [TypeCategoryNotifications.MedicalRisk]: '#2EC28C',
    [TypeCategoryNotifications.InternalSocialTensions]: '#7E90B4',
    [TypeCategoryNotifications.PotentialInsecurity]: '#EA4040',
    [TypeCategoryNotifications.BordersEntry]: '#5440EA',
    [TypeCategoryNotifications.LGBT]:
        'linear-gradient(180deg, #E50000 0%, #FF8D00 20%, #E2C400 40%, #028121 60%, #004CFF 80%, #770088 100%)',
    [TypeCategoryNotifications.TransportUpdates]: '#AC40EA',
    [TypeCategoryNotifications.FemaleTravel]: '#EA4081',
}

export const iconSizeRiskSubCategory: any = {
    [TypeCategoryNotifications.GeopoliticalConflicts]: '18',
    [TypeCategoryNotifications.ClimateEnvironmental]: '18',
    [TypeCategoryNotifications.MedicalRisk]: '18',
    [TypeCategoryNotifications.InternalSocialTensions]: '18',
    [TypeCategoryNotifications.PotentialInsecurity]: '18',
    [TypeCategoryNotifications.BordersEntry]: '16',
    [TypeCategoryNotifications.LGBT]: '16',
    [TypeCategoryNotifications.TransportUpdates]: '16',
    [TypeCategoryNotifications.FemaleTravel]: '16',
}
