import { useEffect, useMemo } from 'react'
import './styles.css'
import { risksConfig } from '@/config/risks.config'
import { useRisksCategory } from '@/core/hooks'
import { CategoryRisks, Languages, SubcategoryRisks } from '@/core/typing'
import logo from '@/assets/images/guido_bot.png'
import labelLogo from '@/assets/images/label-travy.svg'
import { Icon } from '../../icons'
import _ from 'lodash'
import { useAccount } from '@/modules/user/hooks'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/store'
import { appEvents } from '@/core/events'
import {
    PaidElements,
    SubscriptionStatus,
} from '@/modules/subscribe/typing/enums'
import { MenuRedesign } from '../../menu-redesign'
import { SubscriptionPansBtn } from '@/modules/subscribe/components'
import shortSubscription from '@/assets/images/get-pro.svg'
import { FAQBtn } from '@/modules/FAQ/components'
import { ChooseLanguageWidget } from '@/core/widgets/chose-language'
import { AboutUsBtn } from '@/modules/root/components'

interface IProps {
    isOpen: boolean
    toggleBar: (val: boolean) => void
}
export const LeftSideBar = ({ isOpen, toggleBar }: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { account } = useAccount()
    const { active, getActiveCategory, setCategoryRisks } = useRisksCategory()
    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    useEffect(() => {
        getActiveCategory()
    }, [])

    const openPopUpSubscribe = (typeElemment: PaidElements) => {
        appEvents.emit('paidElements', { type: typeElemment })
    }

    const onClickMenuItem = (val: {
        category: string
        subcategory?: string
    }) => {
        if (val?.category === CategoryRisks.Climate && !val.subcategory) {
            setCategoryRisks({
                category: val?.category,
                subcategory: SubcategoryRisks.Weather,
            })
        } else {
            setCategoryRisks(
                val as {
                    category: CategoryRisks
                    subcategory: SubcategoryRisks
                },
            )
        }
    }
    const onClickRisk = (val: { category: string; subcategory?: string }) => {
        toggleBar(true)

        if (isTrial && val.category === CategoryRisks.Requirements) {
            openPopUpSubscribe(PaidElements.EntryReq)
            setCategoryRisks({
                category: CategoryRisks.Owerview,
                subcategory: null,
            })
        } else {
            appEvents.emit('paidElements', { type: null })
            onClickMenuItem(val)
        }
    }

    const openFAQ = () => {
        appEvents.emit('contactUs', { isShow: true })
        appEvents.emit('paidElements', { type: null })
    }

    const openAboutUs = () => {
        appEvents.emit('aboutUs', { isShow: true })
    }

    const fullSubscribeBtn = useMemo(() => {
        if (
            account?.subscription?.status &&
            account?.subscription?.status !== SubscriptionStatus.Trial
        ) {
            return null
        } else if (!isOpen) {
            return (
                <img
                    onClick={() => {
                        appEvents.emit('previewTariffs', {
                            isShow: true,
                        })
                        appEvents.emit('paidElements', { type: null })
                    }}
                    src={shortSubscription}
                    style={{ width: 40, height: 40, cursor: 'pointer' }}
                />
            )
        } else
            return (
                <SubscriptionPansBtn
                    onClick={() => {
                        appEvents.emit('previewTariffs', {
                            isShow: true,
                        })
                        appEvents.emit('paidElements', { type: null })
                    }}
                />
            )
    }, [account, isOpen, lang])

    const faqsLanguages = useMemo(() => {
        if (account) {
            return <AboutUsBtn isOpen={isOpen} onClick={openAboutUs} />
        }
        return (
            <div className={isOpen ? 'contact_us' : 'collapsed_bar'}>
                <div
                    className={'choose_language'}
                    style={!isOpen ? { width: 40, height: 40 } : {}}>
                    <ChooseLanguageWidget
                        hideDropdown={!isOpen}
                        onClick={() => toggleBar(true)}
                        onlyFlag={!isOpen}
                        positionDropdown={{
                            top: 'unset',
                            bottom: lang === Languages.ES ? 130 : 110,
                            left: 0,
                        }}
                    />
                </div>
                <FAQBtn onlyIcon={!isOpen} onClick={openFAQ} />
            </div>
        )
    }, [account, isOpen])

    return (
        <div className={`left-sidebar ${isOpen ? 'open' : ''}`}>
            <Icon
                className={isOpen ? 'sidebar_toggle' : 'hidden'}
                onClick={() => toggleBar(!isOpen)}
                name={'left-arrow'}
                size={16}
                color='#717375'
            />

            <div className='sidebar-content'>
                <div>
                    <div
                        className='logo_container'
                        onClick={() =>
                            onClickRisk({
                                category: CategoryRisks.Owerview,
                                subcategory: null,
                            })
                        }>
                        <img src={logo} className='logo_guido' />
                        <img
                            src={labelLogo}
                            className={
                                isOpen ? 'logo_label' : 'hide_logo_label'
                            }
                        />
                    </div>

                    <div>
                        {risksConfig.map((it, index) => (
                            <MenuRedesign
                                id={`category-${index}`}
                                indexMenu={index}
                                activeCategory={active}
                                onClickMenu={onClickRisk}
                                key={it.key}
                                item={it}
                                close={_.noop}
                            />
                        ))}
                    </div>
                </div>

                <div className='rights_container'>
                    <>{fullSubscribeBtn}</>

                    <>{faqsLanguages}</>

                    <p
                        style={{
                            textWrap: lang !== Languages.EN ? 'wrap' : 'nowrap',
                            height: 15,
                        }}
                        className={!isOpen ? 'collapsed' : 'rights show'}>
                        © 2024 SafeTravy. {t('rightsReserved')}.
                    </p>
                </div>
            </div>
        </div>
    )
}
