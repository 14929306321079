import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Icon } from '../../icons'
import { Modal } from 'antd'
import _ from 'lodash'
import { risksConfig } from '@/config/risks.config'
import {
    useEventsListener,
    useResizeWindow,
    useRisksCategory,
} from '@/core/hooks'
import { CategoryRisks, SubcategoryRisks } from '@/core/typing'
import { PrimaryButton } from '../../buttons'
import {
    PaidElements,
    SubscriptionStatus,
} from '@/modules/subscribe/typing/enums'
import { useAccount } from '@/modules/user/hooks'
import { appEvents } from '@/core/events'
import { PopUpSubscribtion } from '@/modules/subscribe/components'
import { useTranslation } from 'react-i18next'
import { MenuRedesign } from '../../menu-redesign'

export const MobileFilter = () => {
    const { width: windowWidth } = useResizeWindow()
    const { t } = useTranslation()
    const [filter, setFilter] = useState<boolean>(false)
    const { account } = useAccount()

    const [risk, setRisk] = useState<{
        category: CategoryRisks
        subcategory: SubcategoryRisks
    }>(null)

    useEventsListener('previewTariffs', data => setFilter(!data?.isShow), [])
    const toggleFilter = () => {
        setFilter(() => !filter)
        close()
    }
    const [paidCategory, setPaid] = useState<PaidElements>(null)

    const { active, setCategoryRisks } = useRisksCategory()
    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    const close = () => {
        setPaid(null)
    }
    const openPopUpSubscribe = (typeElemment: PaidElements) => {
        setPaid(typeElemment)
    }
    const goTariffs = () => {
        setFilter(false)
        close()
        appEvents.emit('subsribe', {
            isShow: true,
            method: 'buy',
            isUpdate: true,
        })
    }
    const onClickRisk = (val: { category: string; subcategory?: string }) => {
        if (isTrial && val.category === CategoryRisks.Requirements) {
            openPopUpSubscribe(PaidElements.EntryReq)
            setRisk(
                val as {
                    category: CategoryRisks
                    subcategory: SubcategoryRisks
                },
            )
        } else {
            close()
            setRisk(
                val as {
                    category: CategoryRisks
                    subcategory: SubcategoryRisks
                },
            )
        }
    }

    const apply = () => {
        setCategoryRisks(risk)
        setFilter(false)
    }

    useEffect(() => {
        if (
            isTrial &&
            risk?.category === CategoryRisks.Requirements &&
            filter
        ) {
            setPaid(PaidElements.EntryReq)
        }
    }, [filter, isTrial, risk])

    if (windowWidth > 768) return null
    return (
        <>
            <div
                className={styles.filter_btn}
                style={{
                    right:
                        active?.subcategory === SubcategoryRisks.Weather ||
                        active?.subcategory === SubcategoryRisks.SeaLevelRise ||
                        active?.category === CategoryRisks.Requirements
                            ? -58
                            : -109,
                }}>
                <Icon
                    className={styles.icon_filter}
                    onClick={toggleFilter}
                    name={filter ? 'x' : 'menu'}
                    size={24}
                    color='#0F0E0E'
                />
                {active?.category &&
                active?.category !== CategoryRisks.Owerview ? (
                    <div className={styles.filter_indicator} />
                ) : null}
            </div>

            <Modal
                transitionName=''
                maskTransitionName=''
                closeIcon={null}
                footer={null}
                classNames={{
                    content: styles.legen_modal,
                    body: styles.modal_body,
                }}
                open={filter}>
                <div className={styles.legen_modal_content}>
                    <div>
                        {risksConfig.map((it, index) => (
                            <MenuRedesign
                                indexMenu={index}
                                activeCategory={risk}
                                onClickMenu={onClickRisk}
                                key={it.key}
                                item={it}
                                close={() => setFilter(false)}
                            />
                        ))}
                    </div>
                    <div className={styles.applly_btn}>
                        <PrimaryButton
                            label={t('apply')}
                            onClick={apply}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div
                    className={styles.close_modal}
                    onClick={toggleFilter}
                    style={{
                        left:
                            active?.subcategory === SubcategoryRisks.Weather ||
                            active?.subcategory ===
                                SubcategoryRisks.SeaLevelRise ||
                            active?.category === CategoryRisks.Requirements
                                ? 10
                                : 60,
                    }}>
                    <Icon
                        className={styles.icon_filter}
                        name='x'
                        size={24}
                        onClick={_.noop}
                    />
                </div>
                <PopUpSubscribtion
                    hideClose
                    element={paidCategory}
                    txtContent={t('fullSubscriptionRequired')}
                    goTariffs={goTariffs}
                    hideElement={close}
                />
            </Modal>
        </>
    )
}
