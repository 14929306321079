import { WindDirAtom } from './wind-dir.atom'
import { createStyleSheet } from '@/core/helpers'

interface IProps {
    value: any
    keyField: string
}
export const CellAtom = ({ value, keyField }: IProps) => {
    if (value?.wind_dir) {
        return (
            <div style={styles.container}>
                <WindDirAtom direction={value?.wind_dir[keyField]} />
                <p>{value[keyField]}</p>
            </div>
        )
    }
    return <p>{value[keyField]}</p>
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
    },
})
